import {createListenerMiddleware, TypedStartListening} from "@reduxjs/toolkit";
import {AppDispatch, RootState} from "../store";
import {addNotification} from "../slice/notifications-slice";
import {dataPointsServiceApi} from "../api/data-service-api";
import {dataPointsV2TagType} from "../api/api-constants";
import isEqual from "lodash.isequal";
import {AcpEventServiceManager} from "../../notifications/acpEventServiceManager";
import i18n from "../../i18n";
import {clearAnalysisRuns} from "../slice/analysis-runs-slice";
import {projectModelDataApi} from "../api/project-model-data-api";
import {Model} from "../../analysis/dashboard.models";
import {QueryState} from "@reduxjs/toolkit/dist/query/core/apiState";
import {findModelInState} from "../../utils/state";

type SubscriptionListener = TypedStartListening<RootState, AppDispatch>;
const analysisRunsMiddleware = createListenerMiddleware();
const startSubscriptionListening = analysisRunsMiddleware.startListening as SubscriptionListener;

startSubscriptionListening({
  //matcher: isAnyOf(addOrUpdateAnalysisRuns,updateAnalysisRun ),
  predicate: (action, currentState, originalState) => {
    return !isEqual(currentState.analysisRuns, originalState.analysisRuns);
  },
  effect: async (action, listenerApi) => {
    const state = listenerApi.getState();
    const {payload: { modelId } } = action;
    if (!modelId) {
      return;
    }
    const { analysisRuns } = state;
    const modelAnalysisRuns = analysisRuns[modelId]?.runs;
    if (!modelAnalysisRuns) {
      return;
    }
    const runs = Object.keys(modelAnalysisRuns).map(key => modelAnalysisRuns[key]);
    if (runs.length === 0) {
      return;
    }
    const areAllRunsCompleted = runs.every((run) => run.job.status === 'COMPLETED');
    const areAllRunsInError = runs.every((run) => run.job.status === 'FAILED');
    const areSomeRunsInError = runs.some((run) => run.job.status === 'FAILED');
    const areSomeRunsCompleted = runs.some((run) => run.job.status === 'COMPLETED');
    const pendingRuns = runs.some((run) => run.job.status === 'RUNNING');

    //unsubscribe
    if (!pendingRuns) {
      //AcpEventServiceManager.instance.unsubscribeToEventsForModelId(modelId);
      listenerApi.dispatch(clearAnalysisRuns({modelId}));
    }

    if (areAllRunsCompleted) {
      const model = findModelInState(modelId, state['projectDataApi']?.queries);
      const message = {title: i18n.t('simulation.notifications.allSimulationsSuccessTitle'), content: i18n.t('simulation.notifications.allSimulationsCompletedMessage', {modelName: model?.name ?? modelId})};
      listenerApi.dispatch(addNotification({type: 'success', message}));
      listenerApi.dispatch(dataPointsServiceApi.util.invalidateTags([{type: dataPointsV2TagType, id: `${modelId}` }]));
      return;
    }
    if (areAllRunsInError) {
      const message = {title: i18n.t('simulation.notifications.allSimulationsFailedTitle'), content: i18n.t('simulation.notifications.allSimulationsFailedMessage')};
      listenerApi.dispatch(addNotification({type: 'error', message, autoHideDuration: 86400000})); //24 hours
      return;
    }
    if (areSomeRunsInError && areSomeRunsCompleted && !pendingRuns) {
      listenerApi.dispatch(addNotification({type: 'warning', message: 'Some Simulations Failed', autoHideDuration: 86400000}));
      listenerApi.dispatch(dataPointsServiceApi.util.invalidateTags([{type: dataPointsV2TagType, id: `${modelId}` }]));
      return;
    }
    return;
  }
});

export default analysisRunsMiddleware;
