import { useEffect, useState } from "react";
import { Dashboard, StandardDashboard } from "../../../types/layout";
import { DataPointsResult } from "../../../types/metrics";
import { useAreSimulationsRunning } from "./useAreSimulationsRunning";
import { useAtLeastOneAdvFactorCard } from "../useAtLeastOneAdvFactorCard";

export const useIsSimulateButtonDisabled = (
  disableEditButton: boolean,
  currentDashboard: Dashboard | StandardDashboard,
  dataPointsResult: DataPointsResult
) => {
  const [isSimulateButtonDisabled, setIsSimulateButtonDisabled] = useState(disableEditButton);
  const areSimulationsRunning = useAreSimulationsRunning();
  const atLeastOneAdvFactor = useAtLeastOneAdvFactorCard(currentDashboard, dataPointsResult);

  useEffect(() => {
    if (!disableEditButton) {
      if (areSimulationsRunning) {
        // at least 1 simulation is running, so disable Simulate button
        setIsSimulateButtonDisabled(true);
        return;
      }
      setIsSimulateButtonDisabled(!atLeastOneAdvFactor);
    } else {
      setIsSimulateButtonDisabled(true);
    }
  }, [atLeastOneAdvFactor, disableEditButton, areSimulationsRunning]);

  return isSimulateButtonDisabled;
};