import { useEffect, useState } from "react";
import { DataPointsResult, FactorValue } from "../../../types/metrics";
import { useVersionedDataPointValue } from "../../../shared/application/application-hooks";

function areAlmostEqual(a: number, b: number, epsilon = 1e-10) {
  return Math.abs(a - b) < epsilon;
}

const getLpdId = (dataPointsResult?: DataPointsResult) => {
  if (dataPointsResult && dataPointsResult.definitions) {
    const simulationFactor = dataPointsResult.definitions.simulationFactors.find(
      (x) => x.simulationParameter.type === 'lpd'
    );
    const matchingAdvFactors = dataPointsResult.definitions.advancedFactors.filter(
      (factor) => factor.simulationFactor === simulationFactor?.id
    );
    if (matchingAdvFactors.length > 0) {
      return matchingAdvFactors[0]?.id ?? '';
    } else {
      return '';
    }
  } else {
    return '';
  }
};

export const useAreAllSimulationCombinationsDone = (dataPointsResult: DataPointsResult) => {
  const [areAllSimulationsDone, setAreAllSimulationsDone] = useState(false);
  const { data: lpdData } = useVersionedDataPointValue(getLpdId(dataPointsResult));

  useEffect(() => {
    if (dataPointsResult && lpdData) {
      // get all possible LPD simulation values
      const lpdValues = (lpdData.value as FactorValue[]).map((v) => v.value).sort((a, b) => a - b);
      // get all LPD values from the simulations that were actually run for this model
      const analysisRunLpdValues = dataPointsResult.analysisRuns
        .map((value) => value.inputs.filter((v) => v.type === 'lpd'))
        .map((v) => v[0].industryStandardValue.value)
        .sort((a, b) => a - b);

      // if their lengths differ, then we haven't actually run all possible simulations
      if (lpdValues.length !== analysisRunLpdValues.length) {
        setAreAllSimulationsDone(false);
        return;
      }

      for (let i = 0; i < lpdValues.length; i++) {
        if (areAlmostEqual(lpdValues[i], analysisRunLpdValues[i]) === false) {
          setAreAllSimulationsDone(false);
          return;
        }
      }
      setAreAllSimulationsDone(true);
    }
  }, [dataPointsResult, lpdData]);

  return areAllSimulationsDone;
};
