import { Middleware, MiddlewareAPI, isRejected } from '@reduxjs/toolkit';
import { addNotification, displayAppError } from '../slice/notifications-slice';
import i18n from '../../i18n';
import { ErrorData, ForgeStandardErrorMessage } from '../../types/errorData';
import { isForgeStandardError, printForgeStandardErrorDetails } from '../../utils/errorData';
import { QueryOptions, hasQueryOptions } from '../api/base-queries';
import {FormatedNotification} from "../../types/notification";

/**
 * Notifications middleware to catch a fail action in dashboard operations and dispatch the notification
 */
export const errorNotificationsMiddleware: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejected(action)) {
      const forgeStandardErrorMessage = isForgeStandardError(action.payload?.data);
      const queryOptions = hasQueryOptions(action.meta.baseQueryMeta);
      if (forgeStandardErrorMessage) {
        printForgeStandardErrorDetails(forgeStandardErrorMessage);
      }

      if (queryOptions) {
        switch (queryOptions.errorOptions.notificationType) {
          case 'toast':
            api.dispatch(
              addNotification({
                message: extractErrorMessage(
                  forgeStandardErrorMessage,
                  queryOptions,
                  i18n.t('notifications.errorMessage')
                ),
                type: 'error',
                autoHideDuration: queryOptions.errorOptions.autoHideDuration
                  ? queryOptions.errorOptions.autoHideDuration
                  : 6000,
              })
            );
            break;
          case 'fullPage':
            const errorData: ErrorData = {
              displayAppError: true,
              errorMessage: extractErrorMessage(
                forgeStandardErrorMessage,
                queryOptions,
                i18n.t('notifications.errorMessage')
              ) as string,
            };
            api.dispatch(displayAppError(errorData));
            break;
          case 'none':
            break;
        }
      }
    }

    return next(action);
  };

const extractErrorMessage = (
  error: ForgeStandardErrorMessage,
  querOptions: QueryOptions,
  fallbackMessage: string
): string | FormatedNotification => {
  const isFormatedNotification = typeof querOptions.errorOptions.messageOverride === 'object';
  if (isFormatedNotification) {
    const {title, content} = querOptions.errorOptions.messageOverride as FormatedNotification;
    return {title: title || 'Error', content: content || fallbackMessage };
  }
  return querOptions.errorOptions.messageOverride || error?.title || fallbackMessage;
};
