import adpService from "../../webAnalytics/adp";
import {MetricEvaluationResult} from "../../types/metrics";

type DataPointMap = {
  [id: string]:(paramId: string, evaluationResult: MetricEvaluationResult) => void;
};

const embodiedCarbonDataPointId = "parameters.TotalCarbonFactoryParameters:911aa4ab34644d6aa984cbcd476f7d6a-1.0.0";
const operationalCarbonDataPointId = "parameters.TotalCarbonFactoryParameters:2ab94fab4c6d45c09ea0654ed4b42694-1.0.0";

export const analyticsDataPointMap: DataPointMap = {
  [embodiedCarbonDataPointId] : (modelId:string, evaluationResult: MetricEvaluationResult) => {
    const {result: embodiedCarbonValue} = evaluationResult;
    adpService.recordDataPointValueEvent(modelId, 'datapointValue', {modelId: modelId, embodiedCarbonValue: embodiedCarbonValue} as Record<string, unknown>)
  },
  [operationalCarbonDataPointId] : (modelId:string, evaluationResult: MetricEvaluationResult) => {
    const {result: operationalCarbonValue} = evaluationResult;
    adpService.recordDataPointValueEvent(modelId, 'datapointValue', {modelId: modelId, operationalCarbonValue: operationalCarbonValue} as Record<string, unknown>)
  }
};

