import { useParams } from "react-router-dom";

export const useModelId = (): string | null => {
  const { modelId } = useParams<{ modelId: string }>();

  if (modelId) {
    const parts = modelId.split(':');
    return parts[parts.length - 1];
  } else {
    return null;
  }
};