import React from 'react';
import { progressBarPanel } from './Card.stylesheet';
import ProgressRing from '@hig/progress-ring';

export const CardDataLoadingProgress: React.FC< {ringSize?: string} > = ({ringSize}) => {
  return (
    <div style={progressBarPanel}>
      <ProgressRing size={ringSize ?? 's'} />
    </div>
  );
};
