import React from 'react';
import Typography, { typographyVariants } from '@weave-mui/typography';
import i18n from '../i18n';
import Tooltip, { tooltipPlacement, tooltipSize } from '@weave-mui/tooltip';
import Box from '@weave-mui/box';
import Button from '@weave-mui/button';
import styled, { CSSObject } from 'styled-components';

export type ToolTipButtonWrapperProps = {
  toolTipTitle: string;
  description?: string;
  buttonTitle?: string;
  onButtonClickCallBack: () => void;
  customStyle?: CSSObject;
  disabled?: boolean;
};

const baseStyles = {
  ':not(:hover):focus': {
    borderColor: 'rgba(128, 128, 128, 0.5) !important',
    boxShadow: 'none !important'
  },
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textAlign: 'left',
  display: 'block',
};

export const ToolTipButtonWrapper: React.FC<ToolTipButtonWrapperProps> = ({
  toolTipTitle,
  description,
  onButtonClickCallBack,
  buttonTitle,
  customStyle,
  disabled,
}) => {
  return (
    <Tooltip
      title={
        <Box>
          <Typography variant="captionBold">{toolTipTitle}</Typography>
        </Box>
      }
      description={
        <Box>
          <div style={{ marginTop: '14px' }}></div>
          <Typography variant="caption">{description}</Typography>
        </Box>
      }
      arrow
      placement={tooltipPlacement.BOTTOM}
      size={tooltipSize.MEDIUM}
      enterDelay={500}
    >
      <Button
        variant="outlined"
        disabled={disabled}
        sx={customStyle ? { ...customStyle, ...baseStyles } : { ...baseStyles }}
        onClick={onButtonClickCallBack}
      >
        {buttonTitle}
      </Button>
    </Tooltip>
  );
};
