import React, { Component } from 'react';
import LeftModelsSelector from '../../model/LeftModelsSelector';
import i18n from '../../i18n';
import WeaveModalDialog from '../../shared/WeaveModalDialog';
import { connect } from 'react-redux';
import { getThumbnails } from '../../model/actions';
import { removeMultipleECModelsFromQueries } from '../statusVerification';
import * as actions from '../EmbodiedCarbon/actions';
import * as applicationActions from '../../shared/application/actions';
import { EcDefinition } from '../EmbodiedCarbon/types';
import { Dashboard, DashboardProps, Factor, MaterialRow, Model } from '../dashboard.models';
import { configurationModalStyles } from '../../shared/ConnectedModalDialog.stylesheet';
import isequal from 'lodash.isequal';
import { TopLevelTooltip } from '../components';
import { TopLevelTooltipProvider } from '../components/TopLevelTooltip';
import Typography, { typographyVariants } from '@weave-mui/typography';
import { withRouter } from '../../utils/react-router-v6-class-utils';
import { SurfaceType } from '../../consts';
import { flattenEcDefinitions, moveFirstSelectedEcDefinition } from '../EmbodiedCarbon/utils';
import ECTabsContent from './ECTabsContent';
import { AdvancedSearchEcDefinitions } from './AdvancedSearchModal/AdvancedSearchEcDefinitions';

type PrevState = {
  modelId: string;
  modalCategories: ModalCategories[];
  modalOpen: boolean;
  projectId: string;
  tabActivated: number;
  showMaterialAssignmentsWarning: boolean;
  models: Model[];
  projectUseSI: boolean;
};

type ModalCategories = {
  display: true;
  factors: Factor[];
  key: string;
  name: string;
};

type EmbodiedCarbonPageState = {
  modelId: string;
  projectId: string;
  models: Model[];
  tabActivated: number;
  showMaterialAssignmentsWarning: boolean;
  isLeftPanelCollapsed?: boolean;
  notificationModalOpen?: boolean;
  notificationModalTitle?: string;
  notificationModalText?: string;
  notificationModalLinkText?: string;
  modalOpen?: boolean;
  modalCategories?: choices[];
  selectedRowMaterial?: MaterialRow;
  projectUseSI: boolean;
};

type choices = {
  display: boolean;
  key: string;
  name: string;
  factors: Factor[];
};

const { H3_REGULAR, H3_BOLD } = typographyVariants;

export class EmbodiedCarbonPage extends Component<DashboardProps, EmbodiedCarbonPageState> {
  constructor(props: DashboardProps) {
    super(props);
    this.state = this.init();
  }

  init() {
    return {
      modelId: this.props.modelId,
      projectId: this.props.projectId,
      models: [],
      tabActivated: 0,
      showMaterialAssignmentsWarning: false,
      projectUseSI: this.props.dashboard?.useSI,
    };
  }

  componentDidMount() {
    this.loadData();
    this.toggleAssignmentsWarningBanner();
  }

  componentDidUpdate(prevProps: DashboardProps, prevState: PrevState) {
    if (prevProps.modelId !== this.props.modelId) {
      const modelIds: string[] = Object.keys(this.props.modelsData.models);
      if (modelIds.length > 0) {
        const modelsToRemove: string[] = modelIds.filter(
          (modelId: string) => modelId !== this.props.modelId
        );
        removeMultipleECModelsFromQueries(modelsToRemove);
      }
      this.loadData();
    }

    if (!isequal(prevProps.dashboard?.modelECBreakdown, this.props.dashboard?.modelECBreakdown)) {
      this.toggleAssignmentsWarningBanner();
    }

    this.getModelThumbnails();
  }

  componentWillUnmount() {
    const modelsWithBreakdownLength = Object.keys(this.props.modelsWithBreakdown).length;
    if (modelsWithBreakdownLength) {
      this.props.removeModelsWithBreakdown();
    }
    if (this.state.models.length > 0) {
      const modelIds = this.state.models.map((model: Model) => model.id);
      removeMultipleECModelsFromQueries(modelIds);
    }
  }

  loadData() {
    if (!this.props.dashboard) {
      this.props.loadDashboard(this.props.modelId, this.props.projectId, this.props.useV2DataApi);
    }
    this.loadEcDefinitions();

    if (!this.props.dashboard?.modelECBreakdown) {
      this.props.loadECBreakdown(this.props.modelId, this.props.projectId);
    }
  }

  toggleAssignmentsWarningBanner() {
    if (this.props.dashboard?.modelECBreakdown) {
      const containsEmptyEcDefinitions = this.props.dashboard?.modelECBreakdown.constructionItems[
        SurfaceType.ExteriorWall
      ]?.some((construction) =>
        construction.materials.some(
          (material) => material.ecDefinitionAssignment?.ecDefinition === null
        )
      );
      this.setState({
        showMaterialAssignmentsWarning: containsEmptyEcDefinitions,
      });
    }
  }

  getModelThumbnails() {
    if (this.state.models.length > 0) {
      this.props.getThumbnails(this.state.models, this.props.user.forgeBaseUrl);
    }
  }

  onModelChange = (model: Model) => {
    this.props.loadDashboard(model.id, this.props.projectId, this.props.useV2DataApi).then(() => {
      this.props.navigate(`/projects/${this.props.projectId}/embodiedCarbon/model/${model.id}`);
    });
  };

  onCollapsedClick = () => {
    this.setState({ isLeftPanelCollapsed: !this.state.isLeftPanelCollapsed });
  };

  static getDerivedStateFromProps(props: DashboardProps, state: EmbodiedCarbonPageState) {
    const newState: any = {
      modelId: props.modelId,
      projectId: props.projectId,
      models: {},
    };

    newState.models = Object.entries(props.modelsData.models)
      .filter(([key, value]) => (value as any).projectId === props.projectId)
      .map(([key, value]) => value);

    if (
      state.modelId !== newState.modelId ||
      state.projectId !== newState.projectId ||
      state.models.length !== newState.models.length
    ) {
      return newState;
    }

    const lmvStatus = newState.models.find((x) => x.id === newState.modelId)?.lmvStatus;
    if (
      state.models.find((z) => z.id === newState.modelId)?.lmvStatus !== lmvStatus &&
      (lmvStatus === 'Completed' || lmvStatus === 'Error')
    ) {
      return newState;
    }

    return null;
  }

  private closeDialog = () => {
    this.props.closeDialog();
  };

  private loadEcDefinitions = () => {
    this.props.loadEcDefinitions();
  };

  private applyDefinitionMap = (ecDefinition: EcDefinition) => {
    this.setDefinition(this.state.selectedRowMaterial.sha, ecDefinition);
    this.props.closeDialog();
  };

  private setDefinition = (materialSha: string, ecDefinition: EcDefinition) => {
    this.props.putEcDefinitionMap(this.props.modelId, materialSha, ecDefinition);
  };

  private openAdvancedSearch = (row: MaterialRow, hideFlyout) => {
    this.setState({
      selectedRowMaterial: row,
    });
    hideFlyout();
    this.props.openAdvancedSearch();
  };

  // do not remove, function from weave implementation
  private openAdvancedSearchWeave = (material: MaterialRow) => {
   this.setState({
      selectedRowMaterial: material,
    });
   this.props.openAdvancedSearch();
  };

  private setProjectUseSI = (useSI: boolean) => {
    this.setState({
      projectUseSI: useSI
    })
  }

  private renderLayoutTabs = (dashboard: Dashboard) => {
    return (
      <ECTabsContent
        modelId={this.props.modelId}
        projectId={this.props.projectId}
        dashboard={dashboard}
        openAdvancedSearch={this.openAdvancedSearch}
        openAdvancedSearchWeave={this.openAdvancedSearchWeave}
        ecDefinitions={this.props.ecDefinitions}
        setDefinition={this.setDefinition}
        setProjectUseSI={this.setProjectUseSI}
      />
    );
  };

  render() {
    const { isLeftPanelCollapsed, selectedRowMaterial } = this.state;

    let analysisPageModelsClasses = 'analysis-page-models';
    if (isLeftPanelCollapsed) {
      analysisPageModelsClasses += ' analysis-page-models-collapsed';
    }

    const { dashboard } = this.props;
    if (!dashboard) {
      return null;
    }

    const useSI = this.state.projectUseSI;


    const generateAdvancedSearchModalTitle = (): JSX.Element => {
      return (
        <div className={'advanced-search-modal-title'}>
          <Typography variant={H3_REGULAR}>
            {i18n.t('analysis.ec.dialog.advancedSearchTitle')}
          </Typography>
          <Typography variant={H3_BOLD}>{selectedRowMaterial?.constructionName}</Typography>
        </div>
      );
    };
    return (

      <div>
        <TopLevelTooltipProvider>
          <TopLevelTooltip />
            <WeaveModalDialog
              onClose={this.closeDialog}
              open={this.props.showEcDefinitionsDialog}
              header={generateAdvancedSearchModalTitle()}
              sx={{ ...configurationModalStyles }}
            >
              <AdvancedSearchEcDefinitions
                modelId={this.props.modelId}
                ecDefinitions={moveFirstSelectedEcDefinition(
                  flattenEcDefinitions(this.props.ecDefinitions),
                  this.state.selectedRowMaterial?.ecDefinition?.id
                )}
                loadEcDefinitions={this.loadEcDefinitions}
                cancelAction={this.closeDialog}
                useSI={useSI}
                applyAction={this.applyDefinitionMap}
                selectedRow={this.state.selectedRowMaterial}
                shouldSort={true}
              />
            </WeaveModalDialog>
          <div className="analysis-page-wrapper">
            <div className="analysis-page">
              <div className={analysisPageModelsClasses}>
                {
                  <LeftModelsSelector
                    modelId={this.props.modelId}
                    projectId={this.props.projectId}
                    isUnauthorisedAccess={this.props.dashboard.isUnauthorizedAccess}
                    isCollapsed={this.state.isLeftPanelCollapsed}
                    collapseCallback={this.onCollapsedClick}
                    onModelChange={this.onModelChange}
                  />
                }
              </div>
              {this.renderLayoutTabs(dashboard)}
            </div>
          </div>
        </TopLevelTooltipProvider>
      </div>
    );
  }
}

const mapStateToProps = function (state, ownProps) {
  const projectId = ownProps.routeParams.projectId;
  const modelId = ownProps.routeParams.modelId;
    return {
      modelId,
      projectId,
      dashboard: state.embodiedCarbonState.modelById[modelId],
      user: state.userState.user,
      modelsData: state.modelState,
      showEcDefinitionsDialog: state.applicationState.showModalDialog,
      ecDefinitions: state.ecDefinitionsState.ecDefinitions,
      modelsWithBreakdown: state.embodiedCarbonState.modelById,
      useV2DataApi: state.featureFlagsState.flagsLoaded
        ? state.featureFlagsState.featureFlags['use-v2-data-point-api']
        : false,
    };
};

const mapDispatchToProps = {
  loadECBreakdown: actions.loadECBreakdown,
  loadDashboard: actions.loadEcPage,
  getThumbnails,
  closeDialog: applicationActions.closeDialog,
  loadEcDefinitions: actions.loadEcDefinitions,
  openAdvancedSearch: applicationActions.showDialog,
  putEcDefinitionMap: actions.putEcDefinitionMap,
  removeModelsWithBreakdown: actions.removeModelsWithBreakdown,
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(EmbodiedCarbonPage);
const componentWithRouterProps = withRouter(connectedComponent);
export default connectedComponent;
export const EmbodiedCarbonPageWithRouter = componentWithRouterProps;

