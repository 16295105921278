import { ComputeJobStatus } from './task-service-api';

export const GET = 'GET';
export const POST = 'POST';
export const PUT = 'PUT';
export const PATCH = 'PATCH';
export const DELETE = 'DELETE';
export const UncategorizedModels = 'UncategorizedModels';
export const Project = 'Project';
export const Projects = 'Projects';
export const ProjectModels = 'ProjectModels';
export const Models = 'Models';
export const ModelFiles = 'ModelFiles';
export const ModelsEcData = 'ModelsEcData';
export const EcDefinitions = 'EcDefinitions';
export const dashboardTagType = 'Dashboards';
export const dashboardTagTypes = [dashboardTagType];
export const simulationsTagType = 'SimulationsStatus';
export const simulationsTagTypes = [simulationsTagType];
export const dataPointsTagType = 'DataPoints';
export const dataPointsV2TagType = 'DataPointsV2';
export const dataPointsTagTypes = [dataPointsTagType, dataPointsV2TagType];
export const dataPointsDefinitions = 'DataPointsDefinitions';
export const FAKE_MODEL_ID = 'fake-model-id';
export const FAKE_RUNNING_JOBS: ComputeJobStatus[] = [
  {
    request: {
      base64RawURLEncodedUrn: 'base64RawURLEncodedUrn',
      status: 'ACCEPTED',
    },
    job: {
      urn: 'fake-job-urn',
      status: 'RUNNING',
    },
  },
];
