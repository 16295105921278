import PubNub, {CryptoModule, Subscription} from 'pubnub';
import { JobStatus } from '../state/api/task-service-api';
import store from '../state/init-store';
import { userDataApi } from '../state/api/user-data-api';
import {EventServiceCredentials, User} from '../analysis/dashboard.models';
import {updateAnalysisRun} from "../state/slice/analysis-runs-slice";
import {addNotification, hideAllNotifications} from "../state/slice/notifications-slice";
import i18n from "../i18n";

type PubNubMessageResponse = {
  id: string;
  source: string;       // "urn:com.autodesk.forge:moniker:autodesk.acp.dev"
  specversion: string;  // "1.0"
  type: string;         // "autodesk.acp.dev:execution.updated-1.0.0"
  subject: string;      // "urn:autodesk.acp.dev:execution.updated:dXJuOmF1dG9kZXNrOmNvbXB1dGU6ZGV2OmpvYnM6ZXhlY3V0aW9uczpIOEZxT3dZWnhHYWM2UE9xNGRUTE5wNVdIWEhHV09iOTplYWE5MGZkMy03ZDZlLTQ4ZTQtOWUzMy1mOWMwODI0YmFmOTg"
  time: string;         //"2024-11-27T16:31:53Z"
  data:
  {
    payload:
    {
      XXX_executionArn: string; // ""
      XXX_machineFlowchart: string; // ""
      allowedCancelation: string; // 0
      base64RawURLEncodedUrn: string; // "dXJuOmF1dG9kZXNrOmNvbXB1dGU6ZGV2OmpvYnM6ZXhlY3V0aW9uczpIOEZxT3dZWnhHYWM2UE9xNGRUTE5wNVdIWEhHV09iOTplYWE5MGZkMy03ZDZlLTQ4ZTQtOWUzMy1mOWMwODI0YmFmOTg",
      childExecutionRequests: {} // null,
      definition:
      {
        JobDefinitionMetadata:
        {
          urn: string; // "urn:autodesk:compute:dev:definitions:jobs:insightx:energysimulation",
          base64RawURLEncodedUrn: string; // "dXJuOmF1dG9kZXNrOmNvbXB1dGU6ZGV2OmRlZmluaXRpb25zOmpvYnM6aW5zaWdodHg6ZW5lcmd5c2ltdWxhdGlvbg"
        }
      },
      duration:
      {
        seconds: number; // 76,
        nanos: number; // 832000000
      },
      environmentVariables: string[]; // null,
      forgeVersionId: string;
      hurl: string;
      isInteractive: boolean,
      payload:
      {
        XXX_input:
        {
          RunTask:
          {
            heartbeatSeconds: number;
            index: number; // -1,
            inputPath: string; // "$",
            jobUrn: string; // "urn:autodesk:compute:dev:jobs:executions:H8FqOwYZxGac6POq4dTLNp5WHXHGWOb9:eaa90fd3-7d6e-48e4-9e33-f9c0824baf98",
            resource: string; // "urn:autodesk:compute:dev:definitions:workers:insightx:energysimulation",
            taskName: string; // "RunTask"
          }
        },
        isBaseRun: boolean; // true,
        modelId: string; // "f491cac6-e401-4d06-b0c4-03afffae3dd8"
      },
      requestInfo:
      {
        RequestMetadata:
        {
          urn: string; // "urn:autodesk:compute:dev:jobs:requests:H8FqOwYZxGac6POq4dTLNp5WHXHGWOb9:ca457e26-975d-40e2-919d-34d3adf3b1d7",
          base64RawURLEncodedUrn: string; // "dXJuOmF1dG9kZXNrOmNvbXB1dGU6ZGV2OmpvYnM6cmVxdWVzdHM6SDhGcU93WVp4R2FjNlBPcTRkVExOcDVXSFhIR1dPYjk6Y2E0NTdlMjYtOTc1ZC00MGUyLTkxOWQtMzRkM2FkZjNiMWQ3"
        }
      },
      startedAt:
      {
        seconds: number; // 1732724981,
        nanos: number; // 983000000
      },
      status: JobStatus; // "COMPLETED",
      timestamps:
      {
        createdAt:
        {
          seconds: number; // 1732724981,
          nanos: number; // 983000000
        },
        lastUpdatedAt:
        {
          seconds: number; // 1732725113,
          nanos: number; // 506761737
        },
        createdAtUnix: number; // 1732724981,
        lastUpdatedAtUnix: number; // 1732725113
      },
      urn: string; // "urn:autodesk:compute:dev:jobs:executions:H8FqOwYZxGac6POq4dTLNp5WHXHGWOb9:eaa90fd3-7d6e-48e4-9e33-f9c0824baf98"
    }
  }
}

export class AcpEventServiceManager {
  ////////////////////////////////////////////////////////////
  /// singleton area
  private static _instance: AcpEventServiceManager;
  public static get instance() {
    this._instance = this._instance ?? new AcpEventServiceManager();
    return this._instance;
  }
  ////////////////////////////////////////////////////////////
  private readonly _userInfo: User;
  private readonly _userId: string;
  private readonly _moniker;
  private _pubNubInstance: PubNub;
  private _cryptoModule: PubNub.NodeCryptoModule;
  private _subscriptionSet: PubNub.SubscriptionSet;

  private constructor() {
    const { data } = userDataApi.endpoints.getUserData.select()(store.getState());
    this._moniker = data.appMoniker;
    this._userInfo = data;
    this._userId = `${btoa(data.autodeskId).replace(/={1,2}$/, '')}`;
  }

  public init = (credentials: EventServiceCredentials) => {
    // initialisation
    this._pubNubInstance = new PubNub({
      subscribeKey: credentials.pubNubSubscriptionKey,
      userId: this._userId,
      restore: true
    });
    this._pubNubInstance.addListener({
      status: (statusEvent) => {
        switch (statusEvent.category) {
          case  "PNNetworkDownCategory":
            //disconnected
            store.dispatch(addNotification({
              type: 'error', message: {
                title: i18n.t('analysis.error.networkErrorTitle'),
                content: i18n.t('analysis.error.networkErrorMessage')
              }, autoHideDuration: 86400000 //24h
            }));
            break;
          case "PNNetworkUpCategory":
            store.dispatch(hideAllNotifications()); //dismiss all notifications when connection is back
            break;


        }
      }
    })

    this._cryptoModule = CryptoModule.aesCbcCryptoModule({cipherKey: credentials.pubNubCipherKey});
  }

  private onMessage = (messageEvent: PubNub.Subscription.Message) => {
    const decryptedMessage = this._cryptoModule.decrypt(messageEvent.message.toString()) as unknown as PubNubMessageResponse;
    const {data: {payload: { payload: { modelId },  requestInfo: {RequestMetadata: metadata}, status}}} = decryptedMessage;
    console.log(`message for ${modelId}`);
    const updateRun = {
      base64RawURLEncodedUrn: metadata.base64RawURLEncodedUrn,
      status,
      modelId
    };
    console.log(`[OnMessage]: Updating job ${updateRun.base64RawURLEncodedUrn} with status ${updateRun.status}`);
    store.dispatch(updateAnalysisRun(updateRun));
  }

  subscribeToEventsForModelId(modelId: string) {
    const channelName = `${this._moniker}-${modelId}`;
    if (!this._subscriptionSet) {
      this._subscriptionSet = this._pubNubInstance.subscriptionSet({channels: [channelName]});
      this._subscriptionSet.onMessage = this.onMessage;
      this._subscriptionSet.subscribe();
      console.log(`Subscribing to channel: ${channelName}`);
      return;
    }
    if (this._subscriptionSet?.channels.includes(channelName)) {
      console.log(`Already subscribed at channel: ${channelName}`);
      return;
    }
    const newSubscription = this._pubNubInstance.channel(channelName).subscription();
    this._subscriptionSet.addSubscription(newSubscription);
    this._subscriptionSet.subscribe();
    console.log(`Added channel subscription: ${channelName}`);
  }

  unsubscribeToEventsForModelId(modelId?: string) {
   console.log(`Unsubscribing from channel of modelId ${modelId}`);
    const channelName = `${this._moniker}-${modelId}`;
   const subscription = this._subscriptionSet.subscriptions.find((s: any) => s.channelNames.includes(channelName));
   if (!subscription) {
     console.log(`Subscription for modelId ${modelId} was not found in subscriptionSet`);
     return;
   }
   subscription.unsubscribe();
   this._subscriptionSet.removeSubscription(subscription);
  }
}
