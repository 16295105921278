import { LayoutHookReturnType } from '../../../types/layout';
import {
  AdvancedFactorDefinition,
  AnalysisDataPointDefinition,
  AnalysisResult,
  DataPointsDefinitions,
  DataPointsDefinitionType,
  DataPointsResult,
  DataPointType,
  DataPointValue,
  FactorValue,
  FormulaBreakdownItem,
  MetricDefinition,
  SimulationFactorDefinition,
  UnitSystem,
} from '../../../types/metrics';
import { useGetUnitsQuery } from '../../../state/api/data-service-api';
import { useFormulaEvaluatorV2 } from './useFormulaEvaluatorV2';
import { useGetImperialSystem } from '../useCurrentProjectData';
import { useSelector } from 'react-redux';
import { RootState } from '../../../state/store';
import {
  calculateMetricBreakdown,
  getDataPointResultFromDefinition,
} from '../../../dataPoints/utils/dataPointsUtils';
import { getDataDefinitionUnit } from '../../../dataPoints/utils/unitsUtils';
import i18n from '../../../i18n';
import { useDataPointsAnalytics } from '../useDataPointsAnalytics';
import { useBenchmarksV2 } from './useBenchmarksV2';
import { useDataPointsV2, useGetDataPointMapV2 } from './useDataPointsV2';
import {
  BASE_RUN_FACTOR_NAME,
  IN_PROGRESS_ADV_FACTOR_RUN,
  IN_PROGRESS_BASE_RUN,
  NOT_COMPUTED_RUN,
} from '../../../types/jobs';
import { useCurrentAnalysisRunIdV2 } from './useAnalysisRunV2';

/**
 * Custom hook that retrieves the value of a data point.
 * @param dataPointId - The ID of the data point.
 * @param useImperialOverride - Optional flag to override the use of imperial units. (added to support forcing units for reporting constraints such as AIA 2030)
 * @returns An object containing the data point value, along with loading, error, and success flags.
 */
export const useDataPointValueV2 = (
  dataPointId: string,
  useImperialOverride: boolean = null
): LayoutHookReturnType<DataPointValue> => {
  const {
    data: dataPointsResults,
    isLoading: isLoadingDataPoints,
    isSuccess: isSuccessDataPoints,
    isError: isErrorDataPoints,
    isFetching: isFetchingDataPoints,
  } = useDataPointsV2();

  const {
    data: units,
    isLoading: isLoadingUnits,
    isSuccess: isSuccessUnits,
    isError: isErrorUnits,
    isFetching: isFetchingUnits,
  } = useGetUnitsQuery();

  const {
    data: formulaEvaluator,
    isLoading: isLoadingFormulaEvaluator,
    isError: isErrorFormulaEvaluator,
    isSuccess: isSuccessFormulaEvaluator,
    isFetching: isFetchingFormulaEvaluator,
  } = useFormulaEvaluatorV2();

  const {
    data: dataPointsMap,
    isLoading: isLoadingDataPointsMap,
    isError: isErrorDataPointsMap,
    isFetching: isFetchingDataPointsMap,
    isSuccess: isSuccessDataPointsMap,
  } = useGetDataPointMapV2();

  let {
    data: useImperial,
    isLoading: isLoadingImperialSystem,
    isSuccess: isSuccessImperialSystem,
    isFetching: isFetchingImperialSystem,
    isError: isErrorImperialSystem,
  } = useGetImperialSystem();

  const {
    data: metricsBenchmarks,
    isLoading: isLoadingBenchmarks,
    isError: isErrorBenchmarks,
    isSuccess: isSuccessBenchmarks,
    isFetching: isFetchingBenchmarks,
  } = useBenchmarksV2();

  let { data: sendAnalytics } = useDataPointsAnalytics(dataPointId);

  let isLoading: boolean =
    isLoadingDataPoints ||
    isLoadingUnits ||
    isLoadingFormulaEvaluator ||
    isLoadingDataPointsMap ||
    isLoadingImperialSystem ||
    isLoadingBenchmarks;

  let isError: boolean =
    isErrorDataPoints ||
    isErrorUnits ||
    isErrorFormulaEvaluator ||
    isErrorDataPointsMap ||
    isErrorImperialSystem ||
    isErrorBenchmarks;

  const isSuccess: boolean =
    isSuccessDataPoints &&
    isSuccessUnits &&
    isSuccessFormulaEvaluator &&
    isSuccessDataPointsMap &&
    isSuccessImperialSystem &&
    isSuccessBenchmarks;

  const isFetching: boolean =
    isFetchingDataPoints ||
    isFetchingUnits ||
    isFetchingFormulaEvaluator ||
    isFetchingDataPointsMap ||
    isFetchingImperialSystem ||
    isFetchingBenchmarks;

  // trigger recalculations when factorOverrides change
  const factorOverrides = useSelector((state: RootState) => state.factorDataState.factorOverrides);
  const selectedAnalysisRun = useCurrentAnalysisRunIdV2();
  const runIsComputed = selectedAnalysisRun !== NOT_COMPUTED_RUN;
  const advFactorRunIsInProgress = selectedAnalysisRun === IN_PROGRESS_ADV_FACTOR_RUN;
  const baseRunIsInProgress = selectedAnalysisRun === IN_PROGRESS_BASE_RUN;

  let dataPointValue: DataPointValue = null;
  if (isSuccess && dataPointsResults && dataPointsMap && units && formulaEvaluator) {
    let simulationFactor = '';
    const dataPointDefinition = dataPointsMap.get(dataPointId);
    const { description, id, displayName: name, type } = dataPointDefinition ?? {};
    if (dataPointDefinition && type !== DataPointType.Metric) {
      //metrics are evaluated on the fly so do not have any value
      let dataPointResult = getDataPointResultFromDefinition(
        dataPointDefinition,
        dataPointsResults,
        selectedAnalysisRun
      );
      let value = null;
      let hasError = false;
      if (
        dataPointDefinition.type === DataPointType.ModelData ||
        dataPointDefinition.type === DataPointType.AnalysisResult
      ) {
        const analysisResult = dataPointResult as AnalysisResult;
        value =
          (useImperial
            ? analysisResult?.imperialStandardValue?.value
            : analysisResult?.industryStandardValue?.value) ?? analysisResult?.value;
        isLoading =
          isLoading ||
          (analysisResult?.isLoading ?? false) ||
          (advFactorRunIsInProgress &&
            isEnergyAnalysisDataPoint(id, dataPointsResults.definitions));
        hasError = analysisResult?.isError;
      } else {
        value = dataPointResult as FactorValue[];
        // advanced factors require the base run first
        // show loading state until base run is available
        if (dataPointDefinition.type === DataPointType.AdvancedFactor) {
          if (!baseRunIsInProgress) {
            simulationFactor = (dataPointDefinition as AdvancedFactorDefinition).simulationFactor;
            value = applyBaseRunFactorValues(
              dataPointDefinition as AdvancedFactorDefinition,
              dataPointsMap,
              dataPointsResults,
              value,
              useImperial
            );
          } else {
            isLoading = true;
          }
        }
      }

      // handle missing numeric values
      if (dataPointDefinition.unit && !value) {
        value = 0;
      }

      dataPointValue = {
        id,
        simulationFactor,
        name,
        value,
        isComputed:
          !runIsComputed && isEnergyAnalysisDataPoint(id, dataPointsResults.definitions)
            ? false
            : true,
        unitSymbol:
          getDataDefinitionUnit(dataPointDefinition, units, useImperial, dataPointsMap)?.symbol ??
          '',
        type,
        description,
        ...(hasError && {
          error: { reason: 'Error with Datapoint value' },
        }),
        isImperial: useImperial,
      };
    }

    if (dataPointDefinition && type === DataPointType.Metric) {
      const metric = dataPointDefinition as MetricDefinition;

      if (metric) {
        if (!metric.isGlobal) {
          useImperial = metric.unitSystem === UnitSystem.Imperial;
        }
        const unitSymbol =
          getDataDefinitionUnit(metric, units, useImperial, dataPointsMap)?.symbol ?? '';
        try {
          const result = formulaEvaluator(
            metric.formula,
            metric.id,
            useImperial,
            factorOverrides,
            true
          );
          let formulaBreakdown: FormulaBreakdownItem[] = calculateMetricBreakdown(
            metric,
            formulaEvaluator,
            dataPointsMap,
            useImperial,
            factorOverrides
          );

          const {
            isLoading: metricIsLoadingArgs,
            dataIsPartial: isPartialResult,
            isError: isMetricError,
            isComputed: isMetricComputed,
            value: isPartialResultValue,
          } = getMetricArgumentsStatusInfo(
            result.arguments,
            dataPointsMap,
            selectedAnalysisRun,
            runIsComputed,
            advFactorRunIsInProgress,
            baseRunIsInProgress,
            dataPointsResults,
            dataPointsResults.definitions
          );

          isLoading = isLoading || metricIsLoadingArgs;

          if (!isLoading) {
            sendAnalytics({ analyticsData: result });
          }

          dataPointValue = {
            id,
            name, // needs to use display name to survive renaming
            value: isPartialResult && isPartialResultValue ? isPartialResultValue : result,
            breakdown: formulaBreakdown.filter((breakdown) => breakdown.value > 0),
            unitSymbol: isPartialResult && isPartialResultValue ? null : unitSymbol,
            type,
            description,
            isPartialResult,
            isComputed: isMetricComputed,
            warningMessage:
              isPartialResult && isPartialResultValue
                ? i18n.t('cards.state.invalidMetricCalcStateText')
                : i18n.t('overview.performance.embodiedCarbon.warning'), //TODO: Hardcoded for any metric
            ...(isMetricError && {
              error: { reason: 'Error with Metric Values' },
            }),
            isImperial: useImperial,
            benchmarks: metricsBenchmarks.has(id) ? metricsBenchmarks.get(id) : undefined,
          };
        } catch (e) {
          dataPointValue = {
            id,
            name, // needs to use display name to survive renaming
            value: null,
            unitSymbol: unitSymbol,
            isComputed: true,
            type,
            description,
            error: { reason: 'Error with Metric Values' },
            isImperial: useImperial,
          };
        }
      }
    }
  }
  return {
    data: dataPointValue,
    isError,
    isLoading,
    isSuccess,
    isFetching,
  };
};

export const getMetricArgumentsStatusInfo = (
  metricArguments: string[],
  dataPointsMap: Map<string, DataPointsDefinitionType>,
  runId: string,
  isRunComputed: boolean,
  isAdvFactorRunInProgress: boolean,
  isBaseRunInProgress: boolean,
  dataPointResults: DataPointsResult,
  definitions: DataPointsDefinitions
): {
  isLoading: boolean;
  dataIsPartial: boolean;
  isError: boolean;
  isComputed: boolean;
  value: string;
} => {
  if (!Array.isArray(metricArguments)) {
    return {
      isLoading: false,
      dataIsPartial: true,
      isComputed: false,
      isError: false,
      value: null,
    };
  }

  let isComputed = true;

  // assume if no runs are present, we are processing base run
  if ((!isRunComputed || isAdvFactorRunInProgress) && !isBaseRunInProgress) {
    // does metric contain any OC parts to the formula
    const energyAnalysisDataPoints = definitions.energyAnalysis.map((dp) => dp.id);
    const energyAnalysisPartsInFormula = metricArguments.filter((dpId) =>
      energyAnalysisDataPoints.includes(dpId)
    );

    if (!isRunComputed) {
      return {
        dataIsPartial: false,
        isLoading: false,
        isError: false,
        isComputed: energyAnalysisPartsInFormula.length === 0,
        value: null,
      };
    }

    if (isAdvFactorRunInProgress && energyAnalysisPartsInFormula.length) {
      return {
        dataIsPartial: false,
        isLoading: true,
        isError: false,
        isComputed,
        value: null,
      };
    }
  }

  const dataPointsInUse = metricArguments
    .map((dpId) => dataPointsMap.get(dpId))
    .filter(
      (dp) => dp?.type === DataPointType.ModelData || dp?.type === DataPointType.AnalysisResult
    ) as AnalysisDataPointDefinition[];

  const results = dataPointsInUse.map((dp) =>
    getDataPointResultFromDefinition(dp, dataPointResults, runId)
  ) as AnalysisResult[];

  return {
    dataIsPartial: results.some((x) => !!x?.isPartial),
    isLoading: results.some((x) => !!x?.isLoading),
    isError: results.some((x) => !!x?.isError),
    isComputed,
    value: null,
  };
};

const isEnergyAnalysisDataPoint = (
  dataPointId: string,
  definitions: DataPointsDefinitions
): boolean => !!definitions.energyAnalysis.find((dp) => dp.id === dataPointId);

/**
 * Get the base run value that has been used for the advanced factor and
 * prepend to the list of advanced factor values passed
 * @param {AdvancedFactorDefinition} dataPointDefinition
 * @param {Map<string, DataPointsDefinitionType>} dataPointsMap
 * @param {DataPointsResult} dataPointsResults
 * @param {FactorValue[]} dataPointResult
 * @param {boolean} useImperial
 * @return {*}  {FactorValue[]}
 */
const applyBaseRunFactorValues = (
  dataPointDefinition: AdvancedFactorDefinition,
  dataPointsMap: Map<string, DataPointsDefinitionType>,
  dataPointsResults: DataPointsResult,
  dataPointResult: FactorValue[],
  useImperial: boolean
): FactorValue[] => {
  const simulationDatapoint = dataPointsMap.get(
    (dataPointDefinition as AdvancedFactorDefinition).simulationFactor
  ) as SimulationFactorDefinition;

  const baseRun = dataPointsResults.analysisRuns.find((run) => run.isBaseRun);
  const baseRunInput = baseRun?.inputs.find(
    (input) => input.parameterId === simulationDatapoint.id
  );

  // add base run value to the list
  const baseRunFactorValue: FactorValue = {
    name: BASE_RUN_FACTOR_NAME,
    imperialStandardValue: baseRunInput?.imperialStandardValue,
    industryStandardValue: baseRunInput?.industryStandardValue,
    value: useImperial
      ? baseRunInput?.imperialStandardValue?.value
      : baseRunInput?.industryStandardValue?.value,
  };

  return [baseRunFactorValue, ...dataPointResult];
};
