import i18n from '../../i18n';
import {
  AdvancedFactor,
  AnalysisResult,
  Benchmark,
  DataPoint,
  DataPoints,
  DataPointsDefinitions,
  DataPointsDefinitionType,
  DataPointsResult,
  DataPointType,
  Factor,
  FactorValue,
  FormulaBreakdownItem,
  FormulaCalculationFunction,
  Metric,
  MetricDefinition,
  ParametersModalType,
  ParametersTypesEnum,
  PatchAdvancedFactorRequest,
  PatchMetricRequest
} from '../../types/metrics';
import { ButtonDroptionOption } from '../components/shared/Dropdown/ButtonDropdown';
import { isLongerThanAllowed, TEXT_INPUT_CHARACTERS_LIMIT } from '../../analysis/EmbodiedCarbon/AddECDefinition/helper';
import { hasCustomUnit } from './unitsUtils';
import { FactorsTableRow } from '../components/factors/Table/FactorsTable';
import { UpdateFactorRequest } from '../../types/requests/factors';
import { CreateBenchmarkRequest } from '../../types/requests/benchmarks';
import { BENCHMARK_NAME_LIMIT } from './benchmarksUtils';
import { BASE_RUN } from '../../types/jobs';
import { UpdateAdvancedFactorRequest } from '../../types/requests/advancedFactors';

export const newDataPointId = 'new-parameter-id';
export const TextDataType = 'autodesk.spec.aec:multilineText-';
export const NumberDataType = 'autodesk.spec.aec:number-2.0.0';
export const DISPLAY_NAME_INPUT_CHARACTERS_LIMIT = 100;
export const DESCRIPTION_INPUT_CHARACTERS_LIMIT = 250;
export const UNIT_DISPLAY_CUSTOM_CHARACTER_LIMIT = 16;
export const TABLE_NAME_CHARACTER_LIMIT = 32;
export const TABLE_VALUE_NUMBER_LIMIT = 15;
const notAllowedCharacters = /[\\:{}\[\]`~<>|;?]/;

export const dropdownMetric: ButtonDroptionOption = {
  label: i18n.t('analysis.dataPoints.dropdownValues.metrics'),
  key: ParametersTypesEnum.METRICS,
  tooltip: i18n.t('analysis.dataPoints.dropdownValues.descriptionMetrics'),
};

export const dropdownFactor: ButtonDroptionOption = {
  label: i18n.t('analysis.dataPoints.dropdownValues.factors'),
  key: ParametersTypesEnum.FACTORS,
  tooltip: i18n.t('analysis.dataPoints.dropdownValues.descriptionFactors'),
};

export const dropdownBenchmark: ButtonDroptionOption = {
  label: i18n.t('analysis.dataPoints.dropdownValues.benchmarks'),
  key: ParametersTypesEnum.BENCHMARKS,
  tooltip: i18n.t('analysis.dataPoints.dropdownValues.descriptionBenchmarks'),
};

export const defaultMetric: Metric = {
  id: newDataPointId,
  name: i18n.t('analysis.dataPoints.metrics.newMetric'),
  displayName: i18n.t('analysis.dataPoints.metrics.newMetric'),
  description: '',
  type: DataPointType.Metric,
  dataType: '',
  formula: '',
  unit: '',
  isGlobal: false,
};

export const defaultFactor: Factor = {
  id: newDataPointId,
  name: i18n.t('analysis.dataPoints.factors.newFactor'),
  displayName: i18n.t('analysis.dataPoints.factors.newFactor'),
  description: '',
  type: DataPointType.Factor,
  dataType: '',
  unit: '',
  isGlobal: false,
  dataPointValue: {
    values: [
      {
        name: i18n.t('analysis.dataPoints.factors.defaultValueName'),
        value: 0,
      },
    ],
  },
};

export const defaultBenchmark: Benchmark = {
  value: undefined,
  metrics: [],
  description: '',
  id: newDataPointId,
  name: 'New Benchmark',
  displayName: 'New Benchmark',
  type: DataPointType.Benchmark,
};

export const emptyBenchmark: Benchmark = {
  id: '',
  description: '',
  displayName: '',
  metrics: [],
  name: '',
  type: DataPointType.Benchmark,
  value: undefined,
};

export const getDefaultDataPoint = (
  modalType: ParametersModalType
): Metric | Factor | Benchmark => {
  switch (modalType) {
    case ParametersTypesEnum.METRICS:
      return defaultMetric;
    case ParametersTypesEnum.FACTORS:
      return defaultFactor;
    case ParametersTypesEnum.BENCHMARKS:
      return defaultBenchmark;
    default:
      return defaultMetric;
  }
};
const isCustomUnitAndValid = (dataPoint: Metric | Factor) => {
  if (hasCustomUnit(dataPoint)) {
    return !isLongerThanAllowed(dataPoint.unit, UNIT_DISPLAY_CUSTOM_CHARACTER_LIMIT);
  }
  return true;
};

export const areInputsValid = (
  dataPoint: Metric | Factor | Benchmark,
  modalType: ParametersModalType
) => {
  if (modalType === ParametersTypesEnum.BENCHMARKS) {
    return (
      !isLongerThanAllowed(dataPoint.displayName, BENCHMARK_NAME_LIMIT) &&
      !isLongerThanAllowed(dataPoint.description, DESCRIPTION_INPUT_CHARACTERS_LIMIT) &&
      !containsSpecialCharacters(dataPoint.displayName) &&
      !isLongerThanAllowed(`${(dataPoint as Benchmark).value}`, TABLE_VALUE_NUMBER_LIMIT - 1) &&
      !isNaN(+(dataPoint as Benchmark).value)
    );
  }

  return (
    !isLongerThanAllowed(dataPoint.displayName, TEXT_INPUT_CHARACTERS_LIMIT) &&
    !isLongerThanAllowed(dataPoint.description, DESCRIPTION_INPUT_CHARACTERS_LIMIT) &&
    isCustomUnitAndValid(dataPoint as Metric | Factor) &&
    !containsSpecialCharacters(dataPoint.displayName)
  );
};

export const generateEditMetricObject = (
  updatedMetric: Metric,
  originalMetric: Metric
): PatchMetricRequest => {
  const isPropertyModified = (property: keyof Metric): boolean =>
    originalMetric[property] !== updatedMetric[property];
  const NAME = 'name';
  const DISPLAY_NAME = 'displayName';

  const editMetric: PatchMetricRequest = {
    id: originalMetric.id,
  };

  Object.keys(originalMetric).forEach((property) => {
    const prop = property as keyof Metric;
    if (isPropertyModified(prop)) {
      editMetric[prop] = updatedMetric[prop];
    }
  });

  if (NAME in editMetric && DISPLAY_NAME in editMetric) {
    delete editMetric.name;
  }

  return editMetric;
};

export const generateEditAdvancedFactorObject = (
  updatedFactor: AdvancedFactor,
  originalFactor: AdvancedFactor,
  modelId: string
): PatchAdvancedFactorRequest => {
  const isPropertyModified = (property: keyof AdvancedFactor): boolean =>
    originalFactor[property] !== updatedFactor[property];

  const editFactor: PatchAdvancedFactorRequest = {
    id: originalFactor.id,
    ...(originalFactor.isGlobal && {simulationFactor: originalFactor.simulationFactor}),
    modelId
  };

  Object.keys(originalFactor).forEach((property) => {
    const prop = property as keyof AdvancedFactor;
    if (prop === 'name' || prop === 'description') {
      if (isPropertyModified(prop)) {
        editFactor[prop] = updatedFactor[prop];
      }
    }
  });
  return editFactor;
};

export const isReferencedMetric = (metricId: string, metrics: Metric[]): boolean => {
  return metrics.some((metric) => metric?.formula?.includes(metricId));
};

export const getDataPointsbyType = <T extends Metric | Factor | Benchmark>(
  dataPoints: DataPoints,
  type: ParametersModalType,
  isSimulationFactorEnabled: boolean = false
): T[] => {
  const sort = (a, b) => (a.displayName?.toLowerCase() > b.displayName?.toLowerCase() ? 1 : -1);
  if (dataPoints) {
    let dataPointsToDisplay = [];

    switch (type) {
      case ParametersTypesEnum.METRICS:
        dataPointsToDisplay = dataPoints.metricsData;
        break;
      case ParametersTypesEnum.ADVANCED_FACTORS:
      case ParametersTypesEnum.FACTORS:
        dataPointsToDisplay = dataPoints.factorsData;
        break;
      case ParametersTypesEnum.BENCHMARKS:
        dataPointsToDisplay = dataPoints.benchmarksData;
        break;
      default:
        dataPointsToDisplay = [];
        break;
    }
    dataPointsToDisplay = [...dataPointsToDisplay].sort(sort);
    if (type === ParametersTypesEnum.FACTORS && isSimulationFactorEnabled) {
      dataPointsToDisplay.push(...[...dataPoints.advancedFactorsData].sort(sort));
    }
    return dataPointsToDisplay;
  }
  return [];
};

export const flattenDataPoints = (dataPoints: DataPoints) => {
  if (!dataPoints) {
    return [];
  }
  return [
    ...dataPoints.modelData,
    ...dataPoints.ecAnalysisData,
    ...dataPoints.energyAnalysisData,
    ...dataPoints.factorsData,
    ...dataPoints.simulationFactorsData,
    ...dataPoints.metricsData,
    ...dataPoints.advancedFactorsData
  ];
};

export const dataPointsToMap = (
  dataPoints: DataPoints
): Map<string, DataPoint | Metric | Factor> => {
  return flattenDataPoints(dataPoints).reduce((acc, dataPoint) => {
    acc.set(dataPoint.id, { ...dataPoint });
    return acc;
  }, new Map<string, DataPoint | Metric | Factor>());
};


// new style to support data point definitions (i.e. with values included)
export const dataPointDefinitionsToMap = (
  dataPoints: DataPointsDefinitions
): Map<string, DataPointsDefinitionType> => {
  return flattenDataPointDefinitions(dataPoints).reduce((acc, dataPoint) => {
    acc.set(dataPoint.id, { ...dataPoint });
    return acc;
  }, new Map<string, DataPointsDefinitionType>());
};

export const flattenDataPointDefinitions = (dataPointDefinitions: DataPointsDefinitions) => {
  if (!dataPointDefinitions) {
    return [];
  }
  return [
    ...dataPointDefinitions.model,
    ...dataPointDefinitions.energyAnalysis,
    ...dataPointDefinitions.ecAnalysis,
    ...dataPointDefinitions.metrics,
    ...dataPointDefinitions.factors,
    ...dataPointDefinitions.advancedFactors,
    ...dataPointDefinitions.simulationFactors,
  ];
};

export const getDataPointValue = (
  dataPoint: DataPoint | Factor,
  useImperial: boolean
): number | FactorValue[] | null => {
  switch (dataPoint?.type) {
    case DataPointType.AdvancedFactor:
    case DataPointType.Factor:
      return (dataPoint as Factor)?.dataPointValue?.values;
    case DataPointType.AnalysisResult:
    case DataPointType.ModelData:
      const dpResult = (dataPoint as DataPoint)?.dataPointValue;
      return (
        (useImperial
          ? dpResult?.imperialStandardValue?.value
          : dpResult?.industryStandardValue?.value) ?? dpResult?.value
      );
    case DataPointType.Metric:
    default:
      return null;
  }
};

export const getDataPointResultFromDefinition = (
  dataPointDefinition: DataPointsDefinitionType,
  results: DataPointsResult,
  runId: string
): AnalysisResult | FactorValue[] | null => {
  switch (dataPointDefinition?.type) {
    case DataPointType.AdvancedFactor:
    case DataPointType.Factor:
      return results.factorsData?.[dataPointDefinition.id];
    case DataPointType.AnalysisResult:
    case DataPointType.ModelData:
      return (
        results.analysisResults?.[dataPointDefinition.id]?.[runId] ||
        results.analysisResults?.[dataPointDefinition.id]?.[BASE_RUN]
      );
    case DataPointType.Metric:
    default:
      return null;
  }
};

export const unescapeBreakdownExpression = (expression: string) => {
  if (!expression) {
    return expression;
  }
  return expression.replaceAll('#{', '').replaceAll('}', '');
};

export const calculateMetricBreakdown = (
  metric: Metric | MetricDefinition,
  formulaEvaluator: FormulaCalculationFunction,
  dataPointsMap: Map<string, DataPoint | Metric | Factor | DataPointsDefinitionType>,
  useImperial: boolean,
  factorOverrides: Record<string, number> = {}
): FormulaBreakdownItem[] => {
  if (!metric?.breakdownFormulas || metric?.breakdownFormulas?.length === 0) {
    return [];
  }
  return metric.breakdownFormulas.map((breakdownItem) => {
    return {
      name: breakdownItem.name
        ? breakdownItem.name
        : dataPointsMap.get(unescapeBreakdownExpression(breakdownItem.expression))?.name,
      value: formulaEvaluator(breakdownItem.expression, metric.id, useImperial, factorOverrides)
        ?.result,
    };
  });
};

export const generateBenchmarkFromRequest = (
  generatedId: string,
  body: CreateBenchmarkRequest
): Benchmark => {
  return {
    id: generatedId,
    name: body.name,
    displayName: body.name,
    description: body.description,
    metrics: body.metrics,
    type: DataPointType.Benchmark,
    value: body.value,
  };
};

export const updateFactor = (factor: Factor, updateFactorRequest: UpdateFactorRequest): Factor => {
  return {
    ...factor,
    dataPointValue: {
      values: [...updateFactorRequest.values],
    },
    description: updateFactorRequest.description,
    displayName: updateFactorRequest.displayName,
    id: updateFactorRequest.id,
    unit: updateFactorRequest.unit,
    name: updateFactorRequest.displayName,
  };
};

export const updateAdvancedFactor = (factor: AdvancedFactor, updateFactorRequest: UpdateAdvancedFactorRequest): Factor => {
  return {
    ...factor,
    description: updateFactorRequest.description ?? factor.description,
    id: updateFactorRequest.id,
    name: updateFactorRequest.name,
  };
};

export const containsSpecialCharacters = (input: string) => {
  return notAllowedCharacters.test(input);
};

//This function checks if the already created metric edit request only has id, to not send a request to DS for nothing
export const isRequestEmpty = (datapoint: PatchMetricRequest): boolean => {
  const props = Object.keys(datapoint);
  return props.length === 1 && props[0] === 'id';
};

export const validateFactorsTableRowValues = (newRow: FactorsTableRow): boolean => {
  if (isNaN(+newRow.value)) return false;
  if (!isFinite(+newRow.value)) return false;
  if (newRow.name.length > TABLE_NAME_CHARACTER_LIMIT) return false;
  if (!newRow.name.trim() || !newRow.value?.toString().trim()) return false;

  return true;
};

export const validateFactorsTableEditValues = (
  newRow: FactorsTableRow,
  oldRow: FactorsTableRow
): boolean => {
  if (!validateFactorsTableRowValues(newRow)) return false;
  if (newRow.name === oldRow.name && +newRow.value === +oldRow.value) return false;
  return true;
};

export const generateNotificationObject = (
  modalType: ParametersModalType,
  onSelectAction: boolean = false
) => {
  let notificationObject = {
    title: i18n.t('analysis.dataPoints.labels.changeNotificationTitle'),
    text: '',
    textPrimaryBn: '',
    textSecondaryBn: i18n.t('analysis.dataPoints.labels.changeNotificationCloseButton'),
  };

  switch (modalType) {
    case ParametersTypesEnum.METRICS:
      notificationObject = {
        ...notificationObject,
        text: i18n.t('analysis.dataPoints.metrics.changeNotificationText'),
        textPrimaryBn: onSelectAction
          ? i18n.t('analysis.dataPoints.metrics.changeNotificationExitButton')
          : i18n.t('analysis.dataPoints.metrics.changeNotificationExitModalButton'),
      };
      break;
    case ParametersTypesEnum.FACTORS:
      notificationObject = {
        ...notificationObject,
        text: i18n.t('analysis.dataPoints.factors.changeNotificationText'),
        textPrimaryBn: onSelectAction
          ? i18n.t('analysis.dataPoints.factors.changeNotificationExitButton')
          : i18n.t('analysis.dataPoints.factors.changeNotificationExitModalButton'),
      };
      break;
    case ParametersTypesEnum.BENCHMARKS:
      notificationObject = {
        ...notificationObject,
        text: i18n.t('analysis.dataPoints.benchmarks.changeNotificationText'),
        textPrimaryBn: onSelectAction
          ? i18n.t('analysis.dataPoints.benchmarks.changeNotificationExitButton')
          : i18n.t('analysis.dataPoints.benchmarks.changeNotificationExitModalButton'),
      };
      break;
  }

  return notificationObject;
};

export const getDataPointById = (paramId:string, dataPoints: DataPoints): Metric | Factor | AdvancedFactor => {
  const param = dataPoints.advancedFactorsData?.find(param => param.id === paramId);
  return param;
}

export const dataPointsMetricBenchmarksMap = (benchMarks: Benchmark[]): Map<string, Benchmark[]> => {
  return benchMarks.reduce((acc, benchmark) => {
    benchmark.metrics?.forEach((metricId) => {
      if (!acc.has(metricId)) {
        acc.set(metricId, [benchmark]);
      } else {
        acc.set(
          metricId,
          [...acc.get(metricId), benchmark].sort((a, b) => b.value - a.value)
        );
      }
    });
    return acc;
  }, new Map<string, Benchmark[]>());
};
