export const controlPanelStyles = {
  width: '100%',
  height: '40px',
  display: 'flex',
  'align-items': 'center',
  'justify-content': 'space-between',
  p: '0px',
  backgroundColor: '#fff',
  boxShadow: `
  rgba(0, 0, 0, 0) -50px 0px 4px 0px,
  rgba(0, 0, 0, 0.05) -40px 0px 4px 0px,
  rgba(0, 0, 0, 0.1) -30px 0px 4px 0px,
  rgba(0, 0, 0, 0.15) -20px 0px 4px 0px,
  rgba(0, 0, 0, 0.2) 0px 0px 4px 0px
  `,
  px: 2,
  position: 'sticky',
  top: 0,
  zIndex: 5,
};

export const controlPanelAddCardsToggleWrapper = {
  display: 'flex',
  alignItems: 'center',
  gap: 1,
};

export const controlPanelDividerWrapper = {
  mx: 2,
};

export const controlPanelDividerStyles = {
  height: 24,
};

export const controlPanelHeaderDividerStyles = {
  height: 24,
  marginLeft: '8px',
  marginRight: '8px'
};

export const controlPanelContentWrapper = {
  height: '100%',
  overflow: 'scroll',
  backgroundColor: '#eeeeee',

  '& div[class*="EmptyState___StyledDiv"]': {
    backgroundColor: 'transparent',
  },
};
export const modelNotificationContainer = {
  height: '100%',
  padding: '1px 2px 16px 0',
};

export const modelNotificationTitle = {
  display: 'flex',
  alignItems: 'start',
  justifyContent: 'start',
  paddingBottom: '5px',
  paddingTop: '16px',
  width: '100%',
};

export const controlPanelContentWithEditModeWrapper = {
  ...controlPanelContentWrapper,
  height: '100%'
};

export const cardGridWrapper = {
  '& .MuiPaper-root:focus': {
    boxShadow: 'none !important',
  },
  '& img[class*="Illustration___"]': {
    display: 'none',
  },
  '& div[class*="Card___StyledDiv"]': {
    boxShadow: 'none',
  },
  height: '100%'
};

export const headerIconStyle = {
  marginRight: '4px',
  paddingBottom: '3px',
};

export const footerButtonStyle = {
  minWidth: '60px',  
};

export const dashboardEmptyStyles = {
  '& div[class*="EmptyGridState__EmptyStateContainer"]': {
    display: 'none',
  },
};

export const layoutEmptyStateStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 'calc(100vh - 130px - 13px)',

  '@media only screen and (min-width: 700px)': {
    height: 'calc(100vh - 120px - 13px)',
  },

  '@media only screen and (min-width: 1024px)': {
    height: 'calc(100vh - 80px - 13px)',
  },
};

export const layoutEmptyStateStylesWithEditMode = {
  ...layoutEmptyStateStyles,
  height: 'calc(100vh - 170px - 13px)',

  '@media only screen and (min-width: 700px)': {
    height: 'calc(100vh - 160px - 13px)',
  },

  '@media only screen and (min-width: 1024px)': {
    height: 'calc(100vh - 120px - 13px)',
  },
};

export const layoutEmptyStateContentStyles = {
  textAlign: 'center',
};

export const layoutGridCardLibraryStyles = {
  height: 'calc(100% - 120px)',
  top: '120px',
  zIndex: 4,

  '& > * > hr[class*="Divider___StyledHr"]': {
    width: '100%',
    margin: 0,
  },

  '& div[class*="Header___StyledDiv2"]': {
    padding: '6px 16px',
  },
};
