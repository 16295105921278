import {QueryState} from "@reduxjs/toolkit/dist/query/core/apiState";
import {Model} from "../analysis/dashboard.models";

export const getV2HookFromState = (state) => state?.featureFlagsState?.featureFlags['use-v2-data-point-api'] as boolean;


export const findModelInState = (modelId: string, projectsState: QueryState<any>): Model => {
  const modelKey = Object.keys(projectsState).find(key => key.includes(modelId));
  return projectsState[modelKey].data as Model;
}
