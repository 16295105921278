import React, { useEffect, useRef } from 'react';
import { CardGrid } from '@adsk/wildcard';
import { Dashboard, WildCardConfiguration } from '../types/layout';
import { LayouGridControlPanel } from './LayoutGridControlPanel';
import { CardLayout, GridSettingsType } from '@adsk/wildcard/es/components/CardGrid/types';
import { wildCardLayoutToDashboard } from './utils/layout-setup';
import Box from '@weave-mui/box';
import {
  controlPanelContentWithEditModeWrapper,
  cardGridWrapper,
  dashboardEmptyStyles,
} from './LayoutGrid.stylesheet';
import { LayoutEmptyState } from './LayoutEmptyState';
import { useDispatch } from 'react-redux';
import { clearAllFactorOverrides } from '../state/slice/factor-data-slice';
import { isElementVisible } from './utils/is-element-visible';
import isEqual from 'lodash.isequal';
import { useCurrentAnalysisRunSelectorV2 } from './hooks/v2/useAnalysisRunV2';

// custom setting for the card grid to enable dragging using card headers
const customGridSettings: GridSettingsType = {
  draggableHandle: '.h-full > div[class*="CardHeader___StyledDiv"]',
};

export const LayoutGrid: React.FC<
  WildCardConfiguration & {
    isStandardDashboard?: boolean;
    isDashboardUpdating?: boolean;
    onCustomSaveAction?: (updatedDashboard: Dashboard) => void;
  }
> = ({
  localCardComponents,
  initialLayout,
  availableCards,
  dashboard,
  isStandardDashboard = false,
  isDashboardUpdating = false,
  onCustomSaveAction,
}) => {
    useCurrentAnalysisRunSelectorV2();
    const dispatch = useDispatch();
    const wrapperRef = useRef(null);
    const cardsScrolled = useRef<string[]>([]);

    // make sure any factor overrides are cleared when the component loaded
    useEffect(() => {
      dispatch(clearAllFactorOverrides());
    }, []);

    const saveCardGrid = (layout: CardLayout[]) => {
      const fixedLayout = layout.map((cardLayout) => {
        return { ...cardLayout, y: isFinite(cardLayout.y) ? cardLayout.y : 0 };
      });
      // transform the layout to a dashboard to save back to the server
      const updatedDashboard = wildCardLayoutToDashboard(fixedLayout, dashboard);

      // The current dashboard is cloned, and the 'version' node is removed to compare it with the updated dashboard.
      const cleanOriginalObject: any = { ...dashboard };
      delete cleanOriginalObject.version;

      // It is updated only if there have been changes
      if (!isEqual(updatedDashboard, cleanOriginalObject)) {
        onCustomSaveAction(updatedDashboard);
      }
    };

    const onNewlyAddedCards = (cards: CardLayout[]) => {
      const cardToBeScrolled = cards.find(({ i }) => {
        const el = document.getElementById(i);
        return el ? !isElementVisible(el) : false;
      });
      if (cardToBeScrolled && !cardsScrolled.current.includes(cardToBeScrolled.i)) {
        const { i } = cardToBeScrolled;
        cardsScrolled.current.push(i);
        const el = document.getElementById(i);
        wrapperRef.current.scrollTo({
          behavior: 'smooth',
          top: el.offsetTop + el.offsetHeight + 10,
        });
      }
    };

    return (
      <Box
        ref={wrapperRef}
        sx={{
          ...controlPanelContentWithEditModeWrapper,
          ...dashboardEmptyStyles,
          ...cardGridWrapper,
        }}
      >
        <CardGrid
          key={dashboard?.dashboardId}
          availableCards={availableCards}
          initialCardLayout={initialLayout}
          localCardComponents={localCardComponents}
          onSave={saveCardGrid}
          gridSettings={customGridSettings}
          onNewlyAddedCards={onNewlyAddedCards}
        >
          <LayouGridControlPanel
            disableEditButton={isStandardDashboard}
            isDashboardUpdating={isDashboardUpdating}
            currentDashboard={dashboard}
          />
          <LayoutEmptyState />
        </CardGrid>
      </Box>
    );
  };
