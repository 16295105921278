import { useSignals } from '@preact/signals-react/runtime';
import React, { useEffect, useState } from 'react';
import { Model } from '../../analysis/dashboard.models';
import { useDataPointValue } from '../../layout/hooks/useDataPointValue';
import { DataPointType, DataPointValue, MetricEvaluationResult } from '../../types/metrics';
import { AiaDdxAuthRequestKeys, AiaDdxImportProjectDataKeys } from '../aia-ddx/const';
import { ReportDataField, ReportSubmissionUnits } from '../types/reporting';
import { ProjectModel } from '../../project/ProjectTypes';
import * as conv from '../../conversions';
import Box from '@weave-mui/box';
import { popperPlacement, spacings, tooltipPlacement, typographyVariants } from '@weave-mui/enums';
import Popper from '@weave-mui/popper';
import Typography from '@weave-mui/typography';
import DataPointsTreeView from '../../shared/DataPointsTreeView/DataPointsTreeView';
import i18n from '../../i18n';
import { CaretDownSUI } from '@weave-mui/icons-weave';
import { InfoTooltipIcon } from '../../shared/InfoTooltipIcon';
import { InfoS } from '@weave-mui/icons-weave';
import {
  useVersionedDataPoints,
  useVersionedDataPointValue,
} from '../../shared/application/application-hooks';

export const NonDataPointValueField: React.FC<{
  field: ReportDataField<AiaDdxAuthRequestKeys | AiaDdxImportProjectDataKeys>;
  model: Model;
  project: ProjectModel;
}> = ({ field, model, project }) => {
  useSignals();
  let calculatedValue: { name: string; value: string } = null;

  useEffect(() => {
    if (field?.dataCalculation?.isFromModel) {
      calculatedValue = field.dataCalculation.isFromModel(model);
    }

    if (field?.dataCalculation?.isFromProject) {
      calculatedValue = field.dataCalculation.isFromProject(project);
    }

    if (calculatedValue) {
      field.data.value = calculatedValue.value;
      field.dataSourceName.value = calculatedValue.name;
    } else {
      field.data.value = '';
    }
  }, []);

  return <>{field.data}</>;
};

export const DataPointValueField: React.FC<{
  field: ReportDataField<AiaDdxAuthRequestKeys | AiaDdxImportProjectDataKeys>;
  reportSubmissionUnits: ReportSubmissionUnits;
}> = ({ field, reportSubmissionUnits }) => {
  useSignals();
  const { data: dataPointDisplayValue } = useVersionedDataPointValue(
    field.dataCalculation.dataPointId.value
  );
  const { data: dataPointSubmissionValue } = useVersionedDataPointValue(
    field.dataCalculation.dataPointId.value,
    reportSubmissionUnits === 'imperial' || null
  );

  const extractDataPointValue = (
    dataPointValue: DataPointValue,
    field: ReportDataField<AiaDdxAuthRequestKeys | AiaDdxImportProjectDataKeys>
  ): string => {
    let value =
      field.dataType !== 'metricData'
        ? dataPointValue.value
        : (dataPointValue.value as MetricEvaluationResult).result;

    value = (typeof value === 'number' ? conv.round(value, 2) : value)?.toString();

    if (field.transformValue) {
      value = field.transformValue(value?.toString());
    }

    return value;
  };

  useEffect(() => {
    if (dataPointDisplayValue) {
      const value = extractDataPointValue(dataPointDisplayValue, field);
      field.data.value = value;
      field.dataSourceName.value = dataPointDisplayValue.name;
      if (field.name !== 'Target EUI' && field.name !== 'Baseline EUI') {
        field.description = dataPointDisplayValue.description;
      }
    }
  }, [dataPointDisplayValue]);

  useEffect(() => {
    if (dataPointSubmissionValue) {
      const value = extractDataPointValue(dataPointSubmissionValue, field);
      field.submissionValue = value;
    }
  }, [dataPointSubmissionValue]);

  return (
    <>
      {field.data}
      {dataPointDisplayValue?.unitSymbol && ' ' + dataPointDisplayValue.unitSymbol}
    </>
  );
};

export const FieldName: React.FC<{
  field: ReportDataField<AiaDdxAuthRequestKeys | AiaDdxImportProjectDataKeys>;
}> = ({ field }) => {
  useSignals();
  const { data: dataPointValue } = useDataPointValue(field.dataCalculation.dataPointId.value);

  return (
    <>{`${field.name}${
      (dataPointValue?.unitSymbol && ' (' + dataPointValue.unitSymbol + ')') || ''
    }`}</>
  );
};

export const DataSourceField: React.FC<{
  field: ReportDataField<AiaDdxAuthRequestKeys | AiaDdxImportProjectDataKeys>;
}> = ({ field }) => {
  useSignals();
  const { data: dataPointValue } = useVersionedDataPointValue(
    field.dataCalculation.dataPointId.value,
    true
  );
  const { data: dataPoints } = useVersionedDataPoints();

  useEffect(() => {
    if (dataPointValue) {
      field.dataSourceName.value = dataPointValue.name;
      field.dataType =
        (dataPointValue.type === DataPointType.Metric && 'metricData') ||
        (dataPointValue.type === DataPointType.AnalysisResult && 'analysisData') ||
        (dataPointValue.type === DataPointType.ModelData && 'modelData') ||
        '';
    }
  }, [dataPointValue]);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Box onClick={handleClick} sx={{ cursor: 'pointer', flexGrow: 1 }}>
        <Typography sx={{ flexGrow: 1, display: 'flex' }} variant={typographyVariants.BODY_REGULAR}>
          {field.dataCalculation.dataPointId.value && (
            <InfoTooltipIcon
              tooltipTitle={
                field.dataSourceName.value || i18n.t('reporting.prompts.missingDataSourceName')
              }
              tooltipContent={field.description}
              icon={<InfoS color="action" />}
              tooltiPlacement={tooltipPlacement.BOTTOM}
              size="medium"
            />
          )}
          {field.dataSourceName.value || i18n.t('reporting.prompts.missingDataSourceName')}
        </Typography>
        <CaretDownSUI />
      </Box>
      <Popper
        arrow={true}
        placement={popperPlacement.TOP_START}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <Box sx={{ padding: spacings.L }}>
          <DataPointsTreeView
            dataPoints={dataPoints}
            isReadOnly={false}
            onClick={(dataPointId) => (field.dataCalculation.dataPointId.value = dataPointId)}
            selectedDataPointId={field.dataCalculation.dataPointId.value}
            activeCategories={['metricsData']}
            displayTextDataType={true}
            customStyle={{
              height: '280px',
              width: '300px',
            }}
          />
        </Box>
      </Popper>
    </>
  );
};
