import React, { useCallback, useState, useRef, ReactNode } from 'react';
import Popper, { popperPlacement } from '@weave-mui/popper';
import Paper, { paperElevations } from '@weave-mui/paper';
import MenuList from '@weave-mui/menu-list';
import MenuItem from '@weave-mui/menu-item';
import { ClickAwayListener } from '@weave-mui/click-away-listener';
import Tooltip, { tooltipPlacement, tooltipSize } from '@weave-mui/tooltip';
import Divider from '@weave-mui/divider';
import Box from '@weave-mui/box';
import Typography from '@weave-mui/typography';

export interface ButtonDroptionOption {
  key: string;
  label: string;
  hide?: boolean;
  tooltip?: string;
}

interface ButtonDropdownProps {
  options: ButtonDroptionOption[];
  arrow?: boolean;
  placement?: typeof popperPlacement;
  onSelect: (key: string) => void;
  children?: ReactNode;
}

const ButtonDropdown: React.FC<ButtonDropdownProps> = ({ options, arrow, placement, onSelect, children }) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = () => setOpen((prevOpen) => !prevOpen);

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  const handleMenuItemClick = useCallback(
    (key) => {
      setOpen(false);
      onSelect(key);
    },
    [onSelect]
  );

  return (
    <>
      <div onClick={handleToggle} ref={anchorRef}>
        {children}
      </div>
      <ClickAwayListener onClickAway={handleClose}>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          onClose={handleClose}
          placement={placement || popperPlacement.BOTTOM}
          arrow={arrow}
          disablePortal
        >
          <Paper elevation={paperElevations.LOW}>
            <MenuList sx={{ width: 'auto', padding: '0px !important' }}>
              {options?.map((option) => {
                if (option.key === 'Divider') return <Divider />;

                if (option.tooltip?.length > 0) {
                  return (
                    <MenuItem
                      onClick={() => handleMenuItemClick(option.key)}
                      sx={option.hide ? { display: 'none' } : { textTransform: 'none !important' }}
                    >
                      <Tooltip
                        key={option.key}
                        arrow
                        placement={tooltipPlacement.LEFT}
                        size={tooltipSize.MEDIUM}
                        enterDelay={500}
                        PopperProps={{
                          modifiers: [
                            {
                              name: 'offset',
                              options: {
                                offset: [0, 10],
                              },
                            },
                          ],
                        }}
                        title={
                          <Box>
                            <Typography variant="captionBold">{option.label}</Typography>
                          </Box>
                        }
                        description={
                          <Box>
                            <div style={{ marginTop: '14px' }}></div>
                            <Typography variant="caption">{option.tooltip}</Typography>
                          </Box>
                        }
                      >
                        <Box>{option.label}</Box>
                      </Tooltip>
                    </MenuItem>
                  );
                }

                return (
                  <MenuItem
                    onClick={() => handleMenuItemClick(option.key)}
                    sx={option.hide ? { display: 'none' } : { textTransform: 'none !important' }}
                  >
                    {option.label}
                  </MenuItem>
                );
              })}
            </MenuList>
          </Paper>
        </Popper>
      </ClickAwayListener>
    </>
  );
}

export default ButtonDropdown;
