import { useEffect, useState } from 'react';
import { Dashboard, StandardDashboard } from '../../types/layout';
import { DataPointsResult, DataPointType } from '../../types/metrics';
import { dataPointDefinitionsToMap } from '../../dataPoints/utils/dataPointsUtils';

export const useAtLeastOneAdvFactorCard = (
  currentDashboard: Dashboard | StandardDashboard,
  dataPointsResult: DataPointsResult
): boolean => {
  const [atLeastOneAdvFactorCard, setAtLeastOneAdvFactorCard] = useState(false);

  useEffect(() => {
    if (dataPointsResult) {
      const definitions = dataPointDefinitionsToMap(dataPointsResult.definitions);
      const atLeast1AdvFactor = currentDashboard.cards.some((card) => {
        const p = definitions.get(card.data.parameterId);
        return p && p.type === DataPointType.AdvancedFactor;
      });
      setAtLeastOneAdvFactorCard(atLeast1AdvFactor);
    }
  }, [currentDashboard, dataPointsResult]);

  return atLeastOneAdvFactorCard;
};
