import { useSignal, useSignals } from '@preact/signals-react/runtime';
import React, { useEffect, useState } from 'react';
import Modal from '@weave-mui/modal';
import Box from '@weave-mui/box';
import { linearProgressVariants, typographyVariants } from '@weave-mui/enums';
import Typography from '@weave-mui/typography';
import LinearProgress from '@weave-mui/linear-progress';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetModelQuery } from '../../state/api/project-model-data-api';
import { useGetLocationQuery } from '../../state/api/location-data-api';
import i18n from '../../i18n';
import Button from '@weave-mui/button';
import { reportValidationSignal } from '../utils/report-validation';
import { ReportData, ReportVariant } from '../types/reporting';
import { AIA_DDX_REPORT_DATA } from '../aia-ddx/const';
import { footerButtonStyle } from '../../layout/LayoutGrid.stylesheet';
import { useVersionedDataPoints } from '../../shared/application/application-hooks';

/**
 * Represents the entry point component for the report.
 * Responsible for fetching the data points and model data required for the report.
 * @param onClose - Callback function to be called when the component is closed.
 */
export const ReportEntryPoint: React.FC<{
  modelId: string;
  variant: ReportVariant;
  onClose: () => void;
}> = ({ modelId, onClose, variant }) => {
  useSignals();
  const navigate = useNavigate();
  const routeParams = useParams();
  const modelResultDataReadyForDisplay = useSignal(false);
  const reportDataLoadingHasError = useSignal(false);
  const reportHasBeenValidated = reportValidationSignal.value.includes(modelId);
  const reportData: ReportData<any> = variant === 'AIA_DDX' && AIA_DDX_REPORT_DATA; // extend to further variants if needed
  const [openDataUnavailableModal, setOpenDataUnavailableModal] = useState(false);
  const [openLoadingModal, setOpenLoadingModal] = useState(false);

  const handleCloseDataUnavailableModal = () => {
    onClose();
    setOpenDataUnavailableModal(false);
  };

  const {
    data: dataPoints,
    isLoading: isDataPointsLoading,
    isSuccess: isGetDataPointsSuccess,
    isFetching: isDataPointsFetching,
    isError: isGetDataPointsError,
  } = useVersionedDataPoints();

  const {
    data: model,
    isSuccess: isModelSuccess,
    isError: isModelError,
  } = useGetModelQuery(modelId, { skip: !modelId || !modelResultDataReadyForDisplay.value });

  const {
    data: location,
    isSuccess: isLocationSuccess,
    isError: isLocationError,
  } = useGetLocationQuery(
    { lng: Number(model?.longitude), lat: Number(model?.latitude) },
    { skip: !model }
  );

  const navigateToReportingPage = () => {
    const path = `/projects/${routeParams.projectId}/model/${modelId}/report`;
    navigate(path);
  };

  useEffect(() => {
    // check is modelId is already in the signal array and validated
    if (reportHasBeenValidated) {
      navigateToReportingPage();
    }
  }, []);

  useEffect(() => {
    reportDataLoadingHasError.value = false;
    if (isGetDataPointsSuccess && !isDataPointsFetching && !isDataPointsLoading) {
      // check if the report data has a validation function and run it
      const reportDataValidationPassed =
        (!reportData?.validateCriticalData && true) ||
        reportData?.validateCriticalData(dataPoints, model, location);
      // set the signal to true if all data points are ready for display
      modelResultDataReadyForDisplay.value =
        dataPoints.energyAnalysisData.every((dataPoint) => {
          const { isLoading, isError, isPartial } = dataPoint.dataPointValue;
          return !isLoading && !isError && !isPartial;
        }) && reportDataValidationPassed;

      if (!modelResultDataReadyForDisplay.value) {
        reportDataLoadingHasError.value = true;
      }
    }

    if (isGetDataPointsError) {
      reportDataLoadingHasError.value = true;
    }
  }, [isGetDataPointsSuccess, isGetDataPointsError, isDataPointsFetching, isDataPointsLoading]);

  useEffect(() => {
    if (isModelSuccess && isLocationSuccess) {
      reportValidationSignal.value.push(modelId);
      navigateToReportingPage();
    }

    if (isModelError || isLocationError) {
      reportDataLoadingHasError.value = true;
    }
  }, [isModelSuccess, isLocationSuccess, isModelError, isLocationError]);

  useEffect(() => {
    if (reportDataLoadingHasError.value) {
      setOpenLoadingModal(false);
      setOpenDataUnavailableModal(true);
    }
    else {
      if (!reportHasBeenValidated) {
        setOpenLoadingModal(true);
        setOpenDataUnavailableModal(false);
      }
    }
  }, [reportDataLoadingHasError.value, reportHasBeenValidated]);

  const displayLoadingModal = () => {
    return (
      <Modal
        header={i18n.t('reporting.aiaddx.heading')}
        open={openLoadingModal}
        sx={{
          '& .MuiModalWeave-closeIcon': {
            display: 'none',
          },
          '& .MuiModalWeave-box': {
            width: '400px',
            height: '150px'
          },
        }}
        disableEscapeKeyDown
      >
        <>
          <Typography sx={{ padding: '10px 0 5px 0' }} variant={typographyVariants.BODY_REGULAR}>
            {i18n.t('reporting.dialog.loadingData')}
          </Typography>
          <Box width="100%">
            <LinearProgress variant={linearProgressVariants.INDETERMINATE} />
          </Box>
        </>
      </Modal>
    );
  };

  const displayErrorModal = () => {
    return (
      <Modal
        header={i18n.t('reporting.dialog.dataUnavailable')}
        open={openDataUnavailableModal}
        onClose={handleCloseDataUnavailableModal}
        actions={
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button sx={{ ...footerButtonStyle }} onClick={onClose}>
              <Typography sx={{ color: '#FFFFFF', fontweight: '600' }} variant="body1Medium">
                {i18n.t('reporting.buttons.ok')}
              </Typography>
            </Button>
          </Box>
        }
        sx={{
          '& .MuiModalWeave-box': {
            width: '400px'
          },
        }}
        disableEscapeKeyDown
      >
        <>
          <Typography variant={typographyVariants.BODY_REGULAR}>
            {i18n.t('reporting.dialog.dataUnavailableMessage')}
          </Typography>
        </>
      </Modal>
    )
  };

  return (
    <>
      {displayLoadingModal()}
      {displayErrorModal()}
    </>
  );
};
