import {createListenerMiddleware, TypedStartListening} from "@reduxjs/toolkit";
import {taskServiceApi} from "../api/task-service-api";
import {AppDispatch, RootState} from "../store";
import {AcpEventServiceManager} from "../../notifications/acpEventServiceManager";
import {addOrUpdateAnalysisRuns} from "../slice/analysis-runs-slice";

type SubscriptionListener = TypedStartListening<RootState, AppDispatch>;
const subscriptionMiddleware = createListenerMiddleware();
const startSubscriptionListening = subscriptionMiddleware.startListening as SubscriptionListener;

startSubscriptionListening({
  matcher: taskServiceApi.endpoints.getTasksForModelId.matchFulfilled,
  effect: async (action, subscriptionApi) => {
    const jobs = action.payload;
    const modelId = action.meta.arg.originalArgs;
    if (jobs && jobs.length > 0) {
      subscriptionApi.dispatch(addOrUpdateAnalysisRuns({runs: jobs, modelId}));
      //we are subscribing when loading the dashboard for the model
      //we don't unsubscribe from the channel
      //AcpEventServiceManager.instance.subscribeToEventsForModelId(modelId);
    }
    return;
  }
});

export default subscriptionMiddleware;
