import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  NotificationToast,
  NotificationToastContainer,
  notificationToastContainerPlacements,
  Box,
  Typography,
} from '@weave-mui/material';
import { dismissNotification } from '../state/slice/notifications-slice';

const convertObjectToarray = (object: object) => {
  const array = Object.entries(object).map(([key, value]) => {
    return value;
  });

  return array;
};

const FormatedNotification = ({title, content}: {title: string; content: string}) => {
  return (<Box>
    <Box>
      <Typography sx={{fontWeight: 'bold'}}>
        {title}
      </Typography>
    </Box>
    <Box>
      <Typography>{content}</Typography>
    </Box>
  </Box>);
};

const Notifications = () => {
  //Get the notifications
  const { notifications }: {notifications: Notification[] } = useSelector((state: { notifications }) => state.notifications);
  const currentNotificationsArray = convertObjectToarray(notifications);

  const dispatch = useDispatch();
  const removeCurrentNotification = useCallback(
    (key: string) => dispatch(dismissNotification(key)),
    [dispatch]
  );

  useEffect(() => {
    let timeout;
    if (currentNotificationsArray.length > 0) {
      const notificationToRemove = currentNotificationsArray[0];
      timeout = setTimeout(() => {
        removeCurrentNotification(notificationToRemove.id);
      }, currentNotificationsArray[0].autoHideDuration || 6000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [currentNotificationsArray, removeCurrentNotification]);


  return currentNotificationsArray.length > 0 ? (
    <Box sx={{ top: '48px' }}>
      <NotificationToastContainer
        placement={notificationToastContainerPlacements.TOP_RIGHT}
        sx={{ top: '48px !important', zIndex: '25000' }}
      >
        {currentNotificationsArray.map((item) => {
          const isFormatedNotification = typeof item.message === 'object';
          return (
          <NotificationToast
            sx={{ width: '340px' }}
            key={item.id}
            onClose={() => removeCurrentNotification(item.id)}
            bar
            severity={item.type}
          >
            <Typography>
              {isFormatedNotification ? <FormatedNotification title={item.message.title} content={item.message.content} /> : <strong>{item.message}</strong>}
            </Typography>
          </NotificationToast>
        );})}
      </NotificationToastContainer>
    </Box>
  ) : null;
};

export default Notifications;
