export const freeEntryTextCardStyles = {
  whiteSpace: 'pre-line',
  'align-items': 'center',
  display: 'flex',
  paddingLeft: '4px',
  wordBreak: 'break-word'
};

export const simpleCardDataStyles = {  
  height: '100%',
  'align-items': 'center',
  paddingLeft: '4px',
};

export const factorCardDataStyles = {
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  flexDirection: 'column',
  overflowY: 'hidden',
};

export const unitSpacing = {
  marginLeft: '4px',
};

export const overflowingText = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: "ellipsis"  
};

// Note: Overwriting default margin and hr with custom styles
export const baseCardStyles = {
  height: '100%',
  display: 'flex',
  '&.h-full > div:first-child': {
    padding: '2px 8px 2px 8px',
  },
  '&.h-full > div:first-child > span': {
    'font-family': 'ArtifaktElement',
    'font-weight': '600',
    'font-size': '12px',
    'line-height': '36px',
  },
  '&.h-full > div:nth-of-type(2)': {
    padding: '8px 8px 8px 8px',
  },
  '&.h-full > hr': {
    marginLeft: '0px',
    width: 'calc(100% - 0px - 0px)',
  },
  '& span[class*="CardHeader___StyledSpan"]': {
    flexGrow: 1,
    maxWidth: '100%',
    overflowX: 'hidden',
  },
  '& div[class*="HeaderActions___StyledDiv"]': {
    visibility: 'visible',
  },
};

export const baseCardCustomizeStyles = {
  ...baseCardStyles,
  '& div[class*="CardHeader___StyledDiv"]': {
    cursor: 'move',
  },
  '& div[class*="Card___StyledDiv"]': {
    cursor: 'default',
  },
  '& .MuiPaper-root': {
    cursor: 'default',
  },
  '& hr[class*="Divider___StyledHr"]': {
    cursor: 'move',
  },
};

export const baseCardHeaderStyles = {
  display: 'flex',
  alignItems: 'center',
  gap: '4px'
};

export const baseCardHeaderTitleStyles = {
  flexGrow: 1,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis'
};

export const progressBarCustomStylesheet = (styles, props, themeData) => ({
  ...styles,
  wrapper: {
    ...styles.wrapper,
    backgroundColor: '#fff',
    marginRight: '10%',
    marginLeft: '10%',
    width: 'calc(100% - 10% - 10%)',
  },
});

export const progressBarPanel = {
  height: '100%',
  borderRadius: '6px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
};

export const cardErrorContainerStyles = {
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  flexDirection: 'column',
};
export const cardErrorInnerContentStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
};

export const cardStateContentAlignment = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 'calc(100% - 20% - 20%)',
  paddingTop: '4px',
};
export const cardStateContent = {
  ...cardStateContentAlignment,
  width: 'calc(100% - 10% - 10%)',
};
export const cardStateFonts = {
  marginRight: '10px',
  textOverflow: 'ellipsis',
  whiteSpace: 'break-spaces',
  textAlign: 'center',
};

export const cardErrorIconPadding = {
  marginRight: '10px',
};
