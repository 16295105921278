import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { AnalysisRunsState } from '../../../state/slice/analysis-runs-slice';
import { useModelId } from '../useModelId';

const areSimulationsRunningSelector = createSelector(
  [(state: { analysisRuns }) => state.analysisRuns, (_, props) => props.modelId],
  (analysisRuns: AnalysisRunsState, modelId) => {
    if (!modelId || !analysisRuns?.hasOwnProperty(modelId)) {
      return false;
    }
    const modelAnalysisRuns = analysisRuns[modelId].runs;
    return Object.keys(modelAnalysisRuns).some(
      (key) => modelAnalysisRuns[key].job.status === 'RUNNING'
    );
  }
);

export const useAreSimulationsRunning = (modelId?: string): boolean => {
  const id = modelId ?? useModelId();
  return useSelector((state) => areSimulationsRunningSelector(state, { modelId: id }));
};
