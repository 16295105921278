import {ComputeJobStatus, JobStatus} from "../api/task-service-api";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export type AnalysisRunsState = Record<string, {runs: Record<string, ComputeJobStatus>}>;

const initialState: AnalysisRunsState = { };
export type UpdateRunRequest = {
  base64RawURLEncodedUrn: string;
  status: JobStatus
  modelId: string;
};
export type AddJobStatusAction = {
  modelId: string;
  runs: ComputeJobStatus[];
};

export const analysisRunsSlice = createSlice({
  name: 'analysisRuns',
  initialState,
  reducers: {
    addOrUpdateAnalysisRuns: (state, action: PayloadAction<AddJobStatusAction>) => {
      const { modelId, runs: jobs } = action.payload;
      if (!state[modelId]) {
        state[modelId] = { runs: { }};
      }

      jobs.forEach(job => {
        state[modelId].runs[job.request.base64RawURLEncodedUrn] = job;
      });
    },
    updateAnalysisRun: (state, {payload}: PayloadAction<UpdateRunRequest>) => {
      const {base64RawURLEncodedUrn, status, modelId} = payload;
      if (!state.hasOwnProperty(modelId)) {
        console.log(`Model not found state: ${modelId}`);
        return;
      }
      const existingJob = state[modelId].runs[base64RawURLEncodedUrn];
      if (existingJob) {
        console.log(`[Slice]: Updating Job ${base64RawURLEncodedUrn} with status ${status}`);
        existingJob.job.status = status;
      } else {
        console.log(`Job not found in state: ${base64RawURLEncodedUrn}`);
      }
    },
    clearAnalysisRuns: (state, action: PayloadAction<{modelId: string}>) => {
     const { modelId } = action.payload;
     state[modelId] = { runs: {} };
    }
  }
});

export const {
  addOrUpdateAnalysisRuns,
  updateAnalysisRun,
  clearAnalysisRuns
} = analysisRunsSlice.actions;
