import { LayoutHookReturnType } from '../../types/layout';
import {
  AnalysisResult,
  DataPoint,
  DataPointType,
  DataPointValue,
  Factor,
  FormulaBreakdownItem,
  Metric,
  UnitSystem,
} from '../../types/metrics';
import { useDataPoints, useGetDataPointMap } from './useDataPoints';
import { useGetUnitsQuery } from '../../state/api/data-service-api';
import { useFormulaEvaluator } from './useFormulaEvaluator';
import { useGetImperialSystem } from './useCurrentProjectData';
import { useSelector } from 'react-redux';
import { RootState } from '../../state/store';
import {
  calculateMetricBreakdown,
  getDataPointValue,
} from '../../dataPoints/utils/dataPointsUtils';
import { getDataPointUnit } from '../../dataPoints/utils/unitsUtils';
import i18n from '../../i18n';
import { useDataPointsAnalytics } from './useDataPointsAnalytics';
import {useBenchmarks} from "./useBenchmarks";

/**
 * Custom hook that retrieves the value of a data point.
 * @param dataPointId - The ID of the data point.
 * @param useImperialOverride - Optional flag to override the use of imperial units. (added to support forcing units for reporting constraints such as AIA 2030)
 * @returns An object containing the data point value, along with loading, error, and success flags.
 */
export const useDataPointValue = (
  dataPointId: string,
  useImperialOverride: boolean = null
): LayoutHookReturnType<DataPointValue> => {
  const {
    data: dataPoints,
    isLoading: isLoadingDataPoints,
    isSuccess: isSuccessDataPoints,
    isError: isErrorDataPoints,
    isFetching: isFetchingDataPoints,
  } = useDataPoints();

  const {
    data: units,
    isLoading: isLoadingUnits,
    isSuccess: isSuccessUnits,
    isError: isErrorUnits,
    isFetching: isFetchingUnits,
  } = useGetUnitsQuery();

  const {
    data: formulaEvaluator,
    isLoading: isLoadingFormulaEvaluator,
    isError: isErrorFormulaEvaluator,
    isSuccess: isSuccessFormulaEvaluator,
    isFetching: isFetchingFormulaEvaluator,
  } = useFormulaEvaluator();

  const {
    data: dataPointsMap,
    isLoading: isLoadingDataPointsMap,
    isError: isErrorDataPointsMap,
    isFetching: isFetchingDataPointsMap,
    isSuccess: isSuccessDataPointsMap,
  } = useGetDataPointMap();

  let {
    data: useImperial,
    isLoading: isLoadingImperialSystem,
    isSuccess: isSuccessImperialSystem,
    isFetching: isFetchingImperialSystem,
    isError: isErrorImperialSystem,
  } = useGetImperialSystem();

  const {
    data: metricsBenchmarks,
    isLoading: isLoadingBenchmarks,
    isError: isErrorBenchmarks,
    isSuccess: isSuccessBenchmarks,
    isFetching : isFetchingBenchmarks,
  } = useBenchmarks();

  let { data: sendAnalytics } = useDataPointsAnalytics(dataPointId);

  let isLoading: boolean =
    isLoadingDataPoints ||
    isLoadingUnits ||
    isLoadingFormulaEvaluator ||
    isLoadingDataPointsMap ||
    isLoadingImperialSystem ||
    isLoadingBenchmarks;

  let isError: boolean =
    isErrorDataPoints ||
    isErrorUnits ||
    isErrorFormulaEvaluator ||
    isErrorDataPointsMap ||
    isErrorImperialSystem ||
    isErrorBenchmarks;

  const isSuccess: boolean =
    isSuccessDataPoints &&
    isSuccessUnits &&
    isSuccessFormulaEvaluator &&
    isSuccessDataPointsMap &&
    isSuccessImperialSystem &&
    isSuccessBenchmarks;

  const isFetching: boolean =
    isFetchingDataPoints ||
    isFetchingUnits ||
    isFetchingFormulaEvaluator ||
    isFetchingDataPointsMap ||
    isFetchingImperialSystem ||
    isFetchingBenchmarks;

  // trigger recalculations when factorOverrides change
  const factorOverrides = useSelector((state: RootState) => state.factorDataState.factorOverrides);
  useImperial = useImperialOverride ?? useImperial;

  let dataPointValue: DataPointValue = null;
  if (isSuccess && dataPoints && dataPointsMap && units && formulaEvaluator) {
    const dataPoint = dataPointsMap.get(dataPointId);
    const { description, id, displayName: name, type } = dataPoint ?? {};
    if (type === DataPointType.AdvancedFactor) {
      throw new Error('Advanced factors are not supported in v1 API');
    }

    if (dataPoint && type !== DataPointType.Metric) {
      //metrics are evaluated on the fly so do not have any value
      let value = getDataPointValue(dataPoint as DataPoint, useImperial);
      let hasError = false;
      if (
        dataPoint.type === DataPointType.ModelData ||
        dataPoint.type === DataPointType.AnalysisResult
      ) {
        const analysisResult = (dataPoint as DataPoint).dataPointValue as AnalysisResult;
        isLoading = isLoading || (analysisResult.isLoading ?? false);
        hasError = analysisResult?.isError;
      }

      // handle missing numeric values
      if (dataPoint.unit && !value) {
        value = 0;
      }

      dataPointValue = {
        id,
        name,
        value,
        isComputed: true,
        unitSymbol: getDataPointUnit(dataPoint, units, useImperial, dataPointsMap)?.symbol ?? '',
        type,
        description,
        ...(hasError && {
          error: { reason: 'Error with Datapoint value' },
        }),
        isImperial: useImperial,
      };
    }

    if (dataPoint && type === DataPointType.Metric) {
      const metric = dataPoint as Metric;

      if (metric) {
        if (!metric.isGlobal) {
          useImperial = metric.unitSystem === UnitSystem.Imperial;
        }
        const unitSymbol = getDataPointUnit(metric, units, useImperial, dataPointsMap)?.symbol ?? '';
        try {
          const result = formulaEvaluator(
            metric.formula,
            metric.id,
            useImperial,
            factorOverrides,
            true
          );
          let formulaBreakdown: FormulaBreakdownItem[] = calculateMetricBreakdown(
            metric,
            formulaEvaluator,
            dataPointsMap,
            useImperial,
            factorOverrides
          );

          const {
            isLoading: metricIsLoadingArgs,
            dataIsPartial: isPartialResult,
            isError: isMetricError,
            value: isPartialResultValue,
          } = getMetricArgumentsStatusInfo(result.arguments, dataPointsMap);

          isLoading = isLoading || metricIsLoadingArgs;

          if (!isLoading) {
            sendAnalytics({ analyticsData: result });
          }

          dataPointValue = {
            id,
            name, // needs to use display name to survive renaming
            value: isPartialResult && isPartialResultValue ? isPartialResultValue : result,
            breakdown: formulaBreakdown.filter((breakdown) => breakdown.value > 0),
            unitSymbol: isPartialResult && isPartialResultValue ? null : unitSymbol,
            type,
            isComputed: true,
            description,
            isPartialResult,
            warningMessage:
              isPartialResult && isPartialResultValue
                ? i18n.t('cards.state.invalidMetricCalcStateText')
                : i18n.t('overview.performance.embodiedCarbon.warning'), //TODO: Hardcoded for any metric
            ...(isMetricError && {
              error: { reason: 'Error with Metric Values' },
            }),
            isImperial: useImperial,
            benchmarks: metricsBenchmarks.has(id) ? metricsBenchmarks.get(id) : undefined
          };
        } catch (e) {
          dataPointValue = {
            id,
            name, // needs to use display name to survive renaming
            value: null,
            unitSymbol: unitSymbol,
            type,
            description,
            error: { reason: 'Error with Metric Values' },
            isImperial: useImperial,
          };
        }
      }
    }
  }
  return {
    data: dataPointValue,
    isError,
    isLoading,
    isSuccess,
    isFetching,
  };
};

export const getMetricArgumentsStatusInfo = (
  metricArguments: string[],
  dataPointsMap: Map<string, DataPoint | Metric | Factor>
): { isLoading: boolean; dataIsPartial: boolean; isError: boolean; value: string } => {
  if (!Array.isArray(metricArguments)) {
    return {
      isLoading: false,
      dataIsPartial: true,
      isError: false,
      value: i18n.t('cardConfig.notApplicableText'),
    };
  }
  const dataPointsInUse = metricArguments
    .map((dpId) => dataPointsMap.get(dpId))
    .filter(
      (dp) => dp?.type === DataPointType.ModelData || dp?.type === DataPointType.AnalysisResult
    ) as DataPoint[];

  return {
    dataIsPartial: dataPointsInUse.some((x) => !!x.dataPointValue?.isPartial),
    isLoading: dataPointsInUse.some((x) => !!x.dataPointValue?.isLoading),
    isError: dataPointsInUse.some((x) => !!x.dataPointValue?.isError),
    value: null,
  };
};
