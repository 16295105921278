export const layoutTabListStyles = {
  minWidth: '0',
  height: 'auto',
  '.MuiTabs-flexContainer': {
    height: '40px',
    width: 'max-content',
    '.MuiDividerWeave': {
      height: '24px !important'
    },
  },
};

export const layoutTabStyles = {
  border: 'none !important',
  ':focus': {
    backgroundColor: '#fff !important',
  },
  '&[aria-selected="true"]': {
    backgroundColor: '#fff !important',
  },
  '&.Mui-selected .MuiTextWeave': {
    paddingRight: '12px',
  },
  borderRadius: '0px !important',
  '.MuiContainerWeave': { pointerEvents: 'unset !important' },
  '.MuiContainerTextWeave': { flexDirection: 'row-reverse' },
  'word-break': 'break-all',
};

export const layoutAddDashboardStyles = {
  height: 'auto',
  '&.MuiButton-text svg *': {
    fill: '#7F7F7F',
  },
};

export const layoutTabPanelWrapperStyles = {
  height: '100%',
  overflow: 'hidden',
  // '@media only screen and (min-width: 700px)': {
  //   height: 'calc(100vh - 120px)',
  // },

  // '@media only screen and (min-width: 1024px)': {
  //   height: 'calc(100vh - 80px)',
  // },
};

export const layoutTabPanelStyles = {
  px: 12,
  py: 3,
  padding: '0px',
  height: '100%',
};

export const layoutBoxStyles = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  backgroundColor: '#fff',
};

export const layoutTabsContainerBoxStyles = {
  p: '0px',
  height: '40px',
  position: 'relative',
  zIndex: 6,
};

export const layoutTabsContainerFixedBoxStyles = {
  position: 'absolute',
  zIndex: 3,
  right: 0,
  left: 0,
  backgroundColor: '#D0D0D0',
  width: '100%',
  height: '40px',
};

export const layoutTabsWrapperBoxStyles = {
  width: 'max-content',
};

export const layoutTabsWrapperWithArrowsBoxStyles = {
  pl: '24px',
  pr: '24px',
};

export const layoutTabsArrowsBoxStyles = {
  position: 'absolute',
  zIndex: 4,
  backgroundColor: '#D0D0D0',
  height: '100%',
  top: 0,
  '&:hover': {
    backgroundColor: '#D0D0D0',
  },
  '&:active': {
    backgroundColor: '#D0D0D0',
  },
  '&:focus': {
    backgroundColor: '#D0D0D0',
  },
};

export const layoutTabsLeftArrowBoxStyles = {
  ...layoutTabsArrowsBoxStyles,
  left: 0,
};

export const layoutTabsRightArrowBoxStyles = {
  ...layoutTabsArrowsBoxStyles,
  right: 0,
};

export const layoutTabsHiddenArrowBoxStyles = {
  display: 'none',
}

export const errorIconTabModelStyles = {
  color: '#DD2222',
  marginRight: '4px',
  marginTop: '2px',
  marginLeft: '0px',
};
