import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ModelWidget from './ModelWidget';
import CreateModelWidget from './CreateModelWidget';
import { Modal } from '../common/Modal';
import '../css/modelListPage.css';
import ModelListSelector from './ModelListSelector';
import { User, Model } from '../analysis/dashboard.models';
import { loadDashboard } from '../analysis/actions';
import { loadEcPage } from '../analysis/EmbodiedCarbon/actions';
import { useGetProjectQuery, useGetProjectModelsQuery } from '../state/api/project-model-data-api';
import { withRouter } from '../utils/react-router-v6-class-utils';
import { useDispatch } from 'react-redux';
import { useIsV2APIEnabled } from '../shared/application/application-hooks';

export type ModelListProps = {
  user?: User;
};

const ModelListPage: React.FC<ModelListProps> = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: isV2DataApiEnabled } = useIsV2APIEnabled();
  const { projectId } = useParams<{ projectId: string }>();
  const [isModelsModalVisible, setIsModelsModalVisible] = useState(false);

  // Redux Toolkit (Queries/Mutations)
  const { data: projectData } = useGetProjectQuery(projectId)
  const { data: projectModelsData } = useGetProjectModelsQuery(projectId)

  useEffect(() => {
    document.body.classList.add('project-detail');
    return () => {
      document.body.classList.remove('project-detail');
    };
  }, [projectId]);

  const showModelsModal = () => {
    setIsModelsModalVisible(!isModelsModalVisible);
  };

  const navigateToDashboard = async (model: Model) => {
    await new Promise<void>((resolve) => {
      loadDashboard(model.id, model.projectId);
      setTimeout(resolve, 2000);
    });
    navigate(`/projects/${model.projectId}/model/${model.id}/runs`);
  };

  const navigateToFlexibleLayout = (model: Model) => {
    const { id, projectId } = model;
    dispatch(loadEcPage(id, projectId, isV2DataApiEnabled));

    navigate(`/projects/${projectId}/model/${id}/layouts`);
  };

  let menuId = 0;
  const models = projectModelsData?.map((model: Model) => {
    menuId++;
    return (
      <ModelWidget
        model={model}
        key={model.id}
        onModelClick={navigateToFlexibleLayout}
        onAnalysisSelected={navigateToDashboard}
        modelMenuId={menuId}
        currentProjectId={projectId}
      />
    );
  });

  if (!projectData) {
    return null;
  }

  return (
    <div className="main-wrapper">
      <div className="main">
        <div id="design_options" className="container-fluid">
          <div className="row no-gutter thumbnail-view styled-scroll">
            {projectData && !projectData.isUncategorized && <CreateModelWidget action={showModelsModal} />}
            {projectData && models}
          </div>
          {isModelsModalVisible && (
            <Modal hideModal={showModelsModal}>
              <ModelListSelector hideModal={showModelsModal} projectId={projectData?.id} />
            </Modal>
          )}
        </div>
      </div>
    </div>
  );
};

const componentWithRouterProps = withRouter(ModelListPage);
export default ModelListPage;
export const ModelListPageWithRouter = componentWithRouterProps;
